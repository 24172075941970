import * as React from "react";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { ILoginLibrary } from "src/models/dto/DashboardModels";
import { Drawer, DrawerContainer } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { DashboardView } from "src/ui/foundation/Layout";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";
import { PanelView } from "../PanelView";
import { AffiliateForm } from "./AffiliateForm";
import { StoreFrontForm } from "./StoreFrontForm";
import StoreStats from "./StoreStats";

export interface IStoreFrontViewProps {
  IsLoggedIn: boolean;
}
export interface IStoreFrontViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  allowedPubs: ILoginLibrary[];
  currentPub: ILoginLibrary | null;
  panelDisabled: boolean;
}
export class StoreFrontView extends React.Component<IStoreFrontViewProps, IStoreFrontViewState> {
  context: AppSession;
  static contextType = AppContext;
  storeListingTable = React.createRef<DataTable>();
  affiliatesTable = React.createRef<DataTable>();
  storeListingStatsTable = React.createRef<DataTable>();
  affiliateStatsTable = React.createRef<DataTable>();

  interval: NodeJS.Timeout | null = null;

  constructor(props: IStoreFrontViewProps) {
    super(props);
    this.state = {
      allowedPubs: [],
      currentDrawerContent: null,
      currentPub: null,
      drawerShow: false,
      panelDisabled: false,
    };
  }
  initialize = () => {
    let allowedPubs = this.context.getManageableStoreListings();
    if (allowedPubs.length > 0) {
      this.context.viewedViews.get(DashboardView.StoreFront)!.progressLoading();

      this.setState({ allowedPubs: allowedPubs, currentPub: allowedPubs[0] });
    }
  };
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.StoreFront)!.loading.on(this.initialize);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.StoreFront)!.loading.off(this.initialize);
  }

  publisherChanged = (e: ILoginLibrary) => {
    this.setState({ currentPub: this.state.allowedPubs.find((x) => x.PublisherId === e.PublisherId)! }, () => {
      this.storeListingTable.current!.reload();
      this.affiliatesTable.current!.reload();
    });
  };

  // #region StoreListing
  private initializeStoreListings = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowStoreListings({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0,
          });
        } else {
          reject();
        }
      });
    });

  private storeListingFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowStoreListings({
          FlowRequest: request.Batches[0],
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({
            Batches: [Convert.indexify(result.data.FlowResponse)],
          });
        } else {
          reject();
        }
      });
    });

  generateStoreListing = (n: INode) => {
    let node = n as Models.IStoreListingViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.StoreListing.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} key={1} className="centerText" value={node.StoreListing.Name} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="centerText" value={node.StoreListing.Description} />);
    dataItems.push(
      <DataItem flexVal={1} key={3} className="centerText" value={Convert.dateToFormattedString(node.StoreListing.StartDate, Languages.English)} />
    );
    if (new Date(node.StoreListing.EndDate) < new Date()) {
      dataItems.push(
        <DataItem flexVal={1} key={4} className="centerText" value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.StoreListing.EndDate, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(
        <DataItem flexVal={1} key={5} className="centerText" value={Convert.dateToFormattedString(node.StoreListing.EndDate, Languages.English)} />
      );
    }

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.storeListingEdit} />;
  };

  storeListingEdit = (n: INode) => {
    let node = n as Models.IStoreListingViewModel;
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <StoreFrontForm initialNode={node} publisherId={this.state.currentPub!.PublisherId} creatingMode={false} saveRequested={this.saveStoreListing} />
      ),
    });
  };

  addStoreListing = () => {
    let blank: Models.IStoreListingViewModel = {
      Index: -1,
      IsFirst: false,
      IsLast: false,
      ProductId: -1,
      StoreListing: {
        TableId: 0,
        TableGuid: "00000000-0000-0000-0000-000000000000",
        Name: "",
        Description: "",
        IsActive: false,
        StartDate: new Date(),
        EndDate: new Date(),
        ProductId: -1,
        PublisherId: this.state.currentPub!.PublisherId,
        IsSpotlight: false,
        Tags: [],
      },
      TermLengths: [],
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <StoreFrontForm initialNode={blank} publisherId={this.state.currentPub!.PublisherId} creatingMode={true} saveRequested={this.saveStoreListing} />
      ),
    });
  };

  private saveStoreListing = async (e: Models.IStoreListingViewModel) => {
    e.PublisherId = this.state.currentPub!.PublisherId;
    let response = await this.context.insertOrUpdateStoreListing({
      StoreListing: e.StoreListing,
      TermLengths: e.TermLengths,
    });
    if (response.valid()) {
      Messages.Notify.success(`The item was saved successfully!`);
      this.storeListingTable.current!.reload();
      
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
    return response.data.StoreListingRef;
  };
  private storeListingsQueryExecute = (query: string) => {
    this.storeListingTable.current!.reload(query);
  };
  // #endregion

  // #region Affiliates
  private initializeAffiliates = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowAffiliates({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0,
          });
        } else {
          reject();
        }
      });
    });

  private affiliateFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowAffiliates({ FlowRequest: request.Batches[0], PublisherId: this.state.currentPub!.PublisherId });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        } else {
          reject();
        }
      });
    });

  private affiliateQueryExecute = (query: string) => {
    this.affiliatesTable.current!.reload(query);
  };
  generateAffiliate = (n: INode) => {
    let node = n as Models.IRegisteredAffiliate;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} className="" key={1} value={node.AffiliateCode} />);
    dataItems.push(
      <DataItem flexVal={2} key={2} className="rightBorder leftBorder centerText" value={Convert.dateToFormattedString(node.StartDate, Languages.English)} />
    );
    if (new Date(node.EndDate) < new Date()) {
      dataItems.push(
        <DataItem flexVal={2} className="centerText" key={3} value={null}>
          <span style={{ color: "red" }}>{Convert.dateToFormattedString(node.EndDate, Languages.English)}</span>
        </DataItem>
      );
    } else {
      dataItems.push(<DataItem flexVal={2} key={3} className="centerText" value={Convert.dateToFormattedString(node.EndDate, Languages.English)} />);
    }
    dataItems.push(<DataItem flexVal={1} key={4} className="centerText" value={node.IsActive ? "Active" : "Inactive"} />);

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.affiliateEdit} />;
  };
  addAffiliate = () => {
    let blank: Models.IRegisteredAffiliate = {
      Index: 0,
      IsFirst: false,
      IsLast: false,
      TableId: 0,
      TableGuid: "00000000-0000-0000-0000-000000000000",
      PublisherId: -1,
      AffiliateCode: "",
      StartDate: new Date(Date.now()),
      EndDate: new Date(Date.now()),
      ReductionType: 0,
      ReductionAmount: 500,
      IsActive: false,
    };
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <AffiliateForm initialNode={blank} publisherId={this.state.currentPub!.PublisherId} saveRequested={this.saveAffiliate} isEditing={false} />
      ),
    });
  };

  private saveAffiliate = async (e: Models.IRegisteredAffiliate) => {
    e.PublisherId = this.state.currentPub!.PublisherId;
    let response = await this.context.insertOrUpdateAffiliate({
      Affiliate: e,
      PublisherId: this.state.currentPub!.PublisherId,
    });
    if (response.valid()) {
      Messages.Notify.success(`The item was saved successfully!`);
      this.affiliatesTable.current!.reload();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private affiliateEdit = (eNode: INode) => {
    let e = eNode as Models.IRegisteredAffiliate;
    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <AffiliateForm
          initialNode={e}
          saveRequested={this.saveAffiliate}
          reloadAffiliates={() => this.affiliatesTable.current!.reload()}
          publisherId={this.state.currentPub!.PublisherId}
          isEditing={true}
        />
      ),
    });
  };
  // #endregion
  render() {
    if (!this.props.IsLoggedIn || this.state.allowedPubs.length <= 0 || !this.context.viewedViews.get(DashboardView.StoreFront)!.isLoaded()) {
      return "";
    }
    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.maxHeight = "500px";
    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          publisherList={this.state.allowedPubs}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.currentPub!}
          disabled={this.state.panelDisabled}
        />
        <div className="storeFrontView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="storeFrontViewInner">
              <div className="section">
                <h1>Welcome to the StoreFront Management View!</h1>
                <ul>
                  <li>View your store listings on the system</li>
                  <li>View descriptions of each store listing</li>
                  <li>Edit your store listing information</li>
                  <li>Create new store listings</li>
                </ul>
              </div>
              {this.context.canManagePublisher(this.state.currentPub!.PublisherId) && <StoreStats currentPub={this.state.currentPub} />}
              <div className="section">
                <h1>Your store listings</h1>
                <p>These are all the current store listings for your publisher.</p>
                <DataTable
                  headers={["Store Listing Name", "Description", "Start Date", "End Date"]}
                  headerFlexes={[2, 2, 1, 1]}
                  flowProvider={this.storeListingFlowProvider}
                  initializeFlowProvider={this.initializeStoreListings}
                  objectBuilder={this.generateStoreListing}
                  ref={this.storeListingTable}
                  settingsOverride={settings}
                  rowAddRequested={this.addStoreListing}
                  searchQueryComitted={this.storeListingsQueryExecute}
                />
              </div>

              <div className="section">
                <h1>Affiliates</h1>
                <p>
                  These are the affiliates. Your affiliate codes are used to track sales that are made in-line with affiliate agreements you may have with
                  influencers.
                </p>
                <DataTable
                  headers={["Code", "Start Date", "End Date", "Status"]}
                  headerFlexes={[2, 2, 2, 1]}
                  flowProvider={this.affiliateFlowProvider}
                  initializeFlowProvider={this.initializeAffiliates}
                  objectBuilder={this.generateAffiliate}
                  ref={this.affiliatesTable}
                  settingsOverride={settings}
                  rowAddRequested={this.addAffiliate}
                  searchQueryComitted={this.affiliateQueryExecute}
                />
              </div>
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
