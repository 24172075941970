import * as React from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { CommentState } from 'src/models/dto/AnnouncementRequest';
import * as Models from 'src/models/dto/DashboardModels';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Messages from '../../foundation/Messages';

export interface ICommentFormProps {
  initialNode: Models.ICommentViewModel;
  goBackButtonClicked: () => void;
  reloadComment?: () => void;
}
export interface ICommentFormState {
  editingNode: Models.ICommentViewModel;
  loading: boolean;
}

export class CommentForm extends React.Component<
  ICommentFormProps,
  ICommentFormState
> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ICommentFormProps) {
    super(props);
    this.state = {
      loading: false,

      editingNode: {
        Comment: this.props.initialNode.Comment,
        Index: this.props.initialNode.Index,
        IsFirst: this.props.initialNode.IsFirst,
        IsLast: this.props.initialNode.IsLast
      }
    };
  }

  changeCommentState(newState: CommentState): void {
    this.setState({ loading: true }, async () => {
      let result = await this.context.updateAnnouncementCommentState({
        TableId: this.state.editingNode.Comment.TableId,
        NewState: newState
      });
      if (result.valid()) {
        Messages.Notify.success("Comment status updated successfully!");
        let current = this.state.editingNode;
        current.CommentState = newState;
        this.setState({ editingNode: current }, () =>
          this.setState({
            editingNode: {
              ...this.state.editingNode,
              Comment: {
                ...this.state.editingNode.Comment,
                CommentState: newState
              }
            }
          })
        );
      } else {
        if (result.errors.length > 0) {
          Messages.Notify.error(
            "Fetch failed. Server reported: " + result.errors[0].Message
          );
        } else {
          Messages.Notify.error(
            "An error occurred while executing the communication"
          );
        }
      }
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <div className="form-container full-width full-height">
        <h2>Comment Management</h2>
        <div>
          Date Posted:{" "}
          {Convert.dateToFormattedString(
            this.state.editingNode.Comment.CreationDate,
            Languages.English
          )}
        </div>

        <div>
          <h4>User ID: {this.state.editingNode.Comment.UserId}</h4>
          <h4>User Name: {this.state.editingNode.Comment.UserName}</h4>
        </div>
        <Col>
          <Row>
            <FormGroup style={{ flex: "1" }}>
              <Label for="comment">Comment</Label>
              <Input
                style={{ minHeight: "250px", maxHeight: "500px" }}
                type="textarea"
                name="comment"
                id="comment"
                value={
                  this.state.editingNode.Comment.Content === null
                    ? ""
                    : this.state.editingNode.Comment.Content
                }
              />
            </FormGroup>
          </Row>
        </Col>
        <FormGroup style={{ position: "relative" }}>
          <Col>
            <Row>
              {this.state.editingNode.Comment.CommentState !==
                CommentState.Published && (
                <Button
                  onClick={() =>
                    this.changeCommentState(CommentState.Published)
                  }
                  style={{ flex: "1", marginRight: "15px" }}
                  outline
                  color="info"
                >
                  Publish Comment
                </Button>
              )}
              {this.state.editingNode.Comment.CommentState !==
                CommentState.Rejected && (
                <Button
                  onClick={() => this.changeCommentState(CommentState.Rejected)}
                  style={{ flex: "1", marginLeft: "15px" }}
                  outline
                  color="danger"
                >
                  Reject Comment
                </Button>
              )}
              {this.props.goBackButtonClicked && (
                <Button
                  onClick={this.props.goBackButtonClicked}
                  style={{ flex: "1", marginLeft: "10px " }}
                  outline
                  color="info"
                >
                  Go Back
                </Button>
              )}
            </Row>
          </Col>
        </FormGroup>
      </div>
    );
  }
}
