import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import {
    UserFormSubmissionState, UserFormSubmissionStateToString
} from 'src/models/dto/UserFormRequest';
import { Loading } from 'src/ui/foundation/Controls';
import { AttachmentForm } from 'src/ui/foundation/Controls/AttachmentForm';
import RichContentControl from 'src/ui/foundation/Controls/RichContentControl';
import richContentControlTester from 'src/ui/foundation/Controls/richContentControlTester';
import TableIdWithOffset from 'src/ui/foundation/Controls/TableIdWithOffset';
import tableIdWithOffsetTester from 'src/ui/foundation/Controls/tableIdWithOffsetTester';
import { TitleSelector } from 'src/ui/foundation/Controls/TitleSelector';
import { UserInfoBlock } from 'src/ui/foundation/Controls/UserInfoBlock';
import { AppContext, UserFormSubmissionContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';

export interface IFeedbackFormProps {
  initialNode: Models.IUserFormViewModel;
  deleteRequested?: (node: Models.IUserFormSubmission) => void;
  saveRequested?: (node: Models.IUserFormSubmission, titles: Models.ITitle[], attac: Models.IUserFormSubmissionAttachment[]) => void;
  reloadFeedbacks?: () => void;
}

export interface IFeedbackFormState {
  editingNode: Models.IUserFormSubmission;
  currentTitles: Models.ITitle[];
  currentAttachments: Models.IUserFormSubmissionAttachment[];
  submissionData: any;
  loading: boolean;
}

enum FeedbackTab {
  Feedbacks,
  Titles,
  Attachments,
}

export class FeedbackForm extends React.Component<IFeedbackFormProps, IFeedbackFormState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IFeedbackFormProps | Readonly<IFeedbackFormProps>) {
    super(props);
    this.state = {
      editingNode: {
        TableId: this.props.initialNode.TableId,
        CreationDate: this.props.initialNode.CreationDate,
        Submission: this.props.initialNode.Submission,
        UserReadReceiptNeeded: this.props.initialNode.UserReadReceiptNeeded,
        UserFormDefinitionId: this.props.initialNode.UserFormDefinitionId,
        BreadCrumb: this.props.initialNode.Breadcrumb,
        LastModificationDate: this.props.initialNode.LastModificationDate,
        SubmissionState: this.props.initialNode.SubmissionState,
        LastModificationUserId: this.props.initialNode.LastModificationUserId,
        ApproverUserId: this.props.initialNode.ApproverUserId,
        SegmentId: null,
        TableGuid: this.props.initialNode.TableGuid,
        UserId: this.props.initialNode.UserId,
        VersionId: null,
      },
      loading: false,
      submissionData: "",
      currentAttachments: [],
      currentTitles: this.props.initialNode.AssociatedTitles,
    };
  }

  async componentDidMount() {
    let attRes = await this.context.getAttachments({ TableId: this.state.editingNode.TableId });

    if (attRes.valid()) {
      this.setState({ currentAttachments: attRes.data.Attachments });
    }
  }

  titlesChanged = (titles: Models.ITitle[]) => {
    this.setState(() => ({
      currentTitles: titles,
    }));
  };

  changeTipState(newState: UserFormSubmissionState): void {
    if (this.props.deleteRequested) {
      this.setState({ loading: true }, async () => {
        let result = await this.context.changeUserFormState({ NewState: newState, UserFormSubmissionId: this.state.editingNode.TableId });
        if (result.valid()) {
          Messages.Notify.success("Submission changed successfully!");
          let current = this.state.editingNode;
          current.SubmissionState = newState;
          this.setState({ editingNode: current }, () =>
            this.setState({ editingNode: { ...this.state.editingNode, Submission: JSON.stringify(this.state.submissionData) } }, () =>
              this.props.saveRequested!(this.state.editingNode, this.state.currentTitles, this.state.currentAttachments)
            )
          );
        } else {
          if (result.errors.length > 0) {
            Messages.Notify.error("Fetch failed. Server reported: " + result.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  }

  handleNewAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  handleUpdatedAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.TableId === e.TableId),
      1
    );
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  deleteAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.Index === e.Index),
      1
    );
    this.setState({ currentAttachments: current });
  };

  render() {
    const schema = JSON.parse(this.props.initialNode.DataSchema);
    const uischema = JSON.parse(this.props.initialNode.DashboardUISchema);

    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Feedback...">
        <div className="form-container full-width full-height">
          <h3>Feedback Management</h3>
          <h4>Current status: {UserFormSubmissionStateToString.UserFormSubmissionStateToString(this.state.editingNode.SubmissionState)}</h4>
          <div className="submissionInfoHeader">
            <span>Created on: {Convert.dateToFormattedString(this.props.initialNode.CreationDate, Languages.English)}</span>
            <span>Last modified on: {Convert.dateToFormattedString(this.props.initialNode.LastModificationDate, Languages.English)}</span>
          </div>
          <div className="feedback-tabs">
            <Tabs defaultActiveKey={0} id="feedbackTabs">
              <Tab eventKey={FeedbackTab.Feedbacks} title={"Feedback"}>
                <div className="full-width full-height feedbackProperties">
                  <h4>Created by</h4>
                  <UserInfoBlock User={this.props.initialNode.CreatedBy} />
                  {this.props.initialNode.ApprovedBy !== null && (
                    <React.Fragment>
                      <h4>Approved by</h4>
                      <UserInfoBlock User={this.props.initialNode.ApprovedBy} />
                    </React.Fragment>
                  )}{" "}
                  <span>
                    <span className="detailHeader">Location in content </span>
                    <span className="locationValue">{this.state.editingNode.BreadCrumb}</span>
                  </span>
                  <UserFormSubmissionContext.Provider value={this.state.editingNode}>
                    <JsonForms
                      schema={schema}
                      uischema={uischema}
                      data={this.state.submissionData === "" ? JSON.parse(this.state.editingNode.Submission) : this.state.submissionData}
                      renderers={[
                        ...materialRenderers,
                        { tester: richContentControlTester, renderer: RichContentControl },
                        { tester: tableIdWithOffsetTester, renderer: TableIdWithOffset },
                      ]}
                      cells={materialCells}
                      onChange={({ data }) => {
                        this.setState({ submissionData: data });
                      }}
                    />{" "}
                  </UserFormSubmissionContext.Provider>
                </div>
              </Tab>
              <Tab eventKey={FeedbackTab.Titles} title={"Titles"}>
                <TitleSelector publisherId={this.props.initialNode.PublisherId} associatedTitles={this.state.currentTitles} onChange={this.titlesChanged} />
              </Tab>
              <Tab eventKey={FeedbackTab.Attachments} title={"Attachments"}>
                <AttachmentForm
                  currentAttachments={this.state.currentAttachments}
                  parentSubmission={this.state.editingNode}
                  handleNewAttachment={this.handleNewAttachment}
                  handleUpdatedAttachment={this.handleUpdatedAttachment}
                  deleteAttachment={this.deleteAttachment}
                />
              </Tab>
            </Tabs>
            <FormGroup>
              <Col>
                <Row>
                  <Button
                    onClick={() => {
                      this.setState({ editingNode: { ...this.state.editingNode, Submission: JSON.stringify(this.state.submissionData) } }, () =>
                        this.props.saveRequested!(this.state.editingNode, this.state.currentTitles, this.state.currentAttachments)
                      );
                    }}
                    style={{ flex: "1", marginRight: "10px" }}
                    outline
                    color="primary"
                  >
                    Save Feedback
                  </Button>
                  {this.state.editingNode.SubmissionState !== UserFormSubmissionState.UnderReview && (
                    <Button
                      onClick={() => this.changeTipState(UserFormSubmissionState.UnderReview)}
                      style={{ flex: "1", marginLeft: "10px" }}
                      outline
                      color="info"
                    >
                      Set as &quot;Under Review&quot;
                    </Button>
                  )}{" "}
                  {this.state.editingNode.SubmissionState !== UserFormSubmissionState.Completed && (
                    <Button
                      onClick={() => this.changeTipState(UserFormSubmissionState.Completed)}
                      style={{ flex: "1", marginLeft: "10px" }}
                      outline
                      color="info"
                    >
                      Set as &quot;Completed&quot;
                    </Button>
                  )}
                  {this.state.editingNode.SubmissionState !== UserFormSubmissionState.Published && (
                    <Button
                      onClick={() => this.changeTipState(UserFormSubmissionState.Published)}
                      style={{ flex: "1", marginLeft: "10px" }}
                      outline
                      color="info"
                    >
                      Set as &quot;Published&quot;
                    </Button>
                  )}
                  {this.state.editingNode.SubmissionState !== UserFormSubmissionState.Rejected && (
                    <Button
                      onClick={() => this.props.deleteRequested!(this.state.editingNode)}
                      style={{ flex: "1", marginLeft: "10px" }}
                      outline
                      color="danger"
                    >
                      Reject Feedback
                    </Button>
                  )}
                </Row>
              </Col>
            </FormGroup>
          </div>
        </div>
      </Loading>
    );
  }
}
