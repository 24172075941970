import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import {
    UserFormSubmissionState, UserFormSubmissionStateToString
} from 'src/models/dto/UserFormRequest';
import { Loading } from 'src/ui/foundation/Controls';
import { AttachmentForm } from 'src/ui/foundation/Controls/AttachmentForm';
import RichContentControl from 'src/ui/foundation/Controls/RichContentControl';
import richContentControlTester from 'src/ui/foundation/Controls/richContentControlTester';
import TableIdWithOffset from 'src/ui/foundation/Controls/TableIdWithOffset';
import tableIdWithOffsetTester from 'src/ui/foundation/Controls/tableIdWithOffsetTester';
import { TitleSelector } from 'src/ui/foundation/Controls/TitleSelector';
import { UserInfoBlock } from 'src/ui/foundation/Controls/UserInfoBlock';
import { DataTable } from 'src/ui/foundation/DataTable';
import { AppContext, UserFormSubmissionContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';

export interface ITipFormProps {
  initialNode: Models.IUserFormViewModel;
  deleteRequested?: (node: Models.IUserFormSubmission) => Promise<void>;
  saveRequested?: (node: Models.IUserFormSubmission, titles: Models.ITitle[], attac: Models.IUserFormSubmissionAttachment[]) => Promise<void>;
  reloadTips?: () => void;
}

export interface ITipFormState {
  editingNode: Models.IUserFormSubmission;
  currentTitles: Models.ITitle[];
  currentAttachments: Models.IUserFormSubmissionAttachment[];
  loading: boolean;
  activeTab: TipTab;
  submissionData: any;
}

enum TipTab {
  Properties,
  Titles,
  Attachments,
}

export class TipForm extends React.Component<ITipFormProps, ITipFormState> {
  context: AppSession;
  static contextType = AppContext;
  associatedTitlesTable = React.createRef<DataTable>();
  availableTitlesTable = React.createRef<DataTable>();
  constructor(props: ITipFormProps | Readonly<ITipFormProps>) {
    super(props);
    this.state = {
      editingNode: {
        TableId: this.props.initialNode.TableId,
        CreationDate: this.props.initialNode.CreationDate,
        Submission: this.props.initialNode.Submission,
        UserReadReceiptNeeded: this.props.initialNode.UserReadReceiptNeeded,
        UserFormDefinitionId: this.props.initialNode.UserFormDefinitionId,
        BreadCrumb: this.props.initialNode.BreadCrumb,
        LastModificationDate: this.props.initialNode.LastModificationDate,
        SubmissionState: this.props.initialNode.SubmissionState,
        LastModificationUserId: this.props.initialNode.LastModificationUserId,
        ApproverUserId: this.props.initialNode.ApproverUserId,
        SegmentId: null,
        TableGuid: this.props.initialNode.TableGuid,
        UserId: this.props.initialNode.UserId,
        VersionId: null,
      },
      loading: false,
      currentAttachments: [],
      currentTitles: this.props.initialNode.AssociatedTitles,
      activeTab: TipTab.Properties,
      submissionData: "",
    };
  }

  titlesChanged = (titles: Models.ITitle[]) => {
    this.setState(() => ({
      currentTitles: titles,
    }));
  };

  saveRequested = () => {
    if (this.props.saveRequested) {
      this.setState({ loading: true }, async () => {
        let edit = this.state.editingNode;
        edit.Submission = JSON.stringify(this.state.submissionData);
        await this.props.saveRequested!(this.state.editingNode, this.state.currentTitles, this.state.currentAttachments);
        this.setState({ loading: false });
      });
    }
  };

  deleteRequested = () => {
    if (this.props.deleteRequested) {
      this.setState({ loading: true }, async () => {
        await this.props.deleteRequested!(this.state.editingNode);
        this.setState({ loading: false });
      });
    }
  };

  async componentDidMount() {
    let attRes = await this.context.getAttachments({ TableId: this.state.editingNode.TableId });

    if (attRes.valid()) {
      this.setState({ currentAttachments: attRes.data.Attachments });
    }
  }

  changeTipState(newState: UserFormSubmissionState): void {
    if (this.props.deleteRequested) {
      this.setState({ loading: true }, async () => {
        let result = await this.context.changeUserFormState({ NewState: newState, UserFormSubmissionId: this.state.editingNode.TableId });
        if (result.valid()) {
          Messages.Notify.success("Submission changed successfully!");
          let current = this.state.editingNode;
          current.SubmissionState = newState;
          this.setState({ editingNode: current }, () =>
            this.setState({ editingNode: { ...this.state.editingNode, Submission: JSON.stringify(this.state.submissionData) } }, () =>
              this.props.saveRequested!(this.state.editingNode, this.state.currentTitles, this.state.currentAttachments)
            )
          );
        } else {
          if (result.errors.length > 0) {
            Messages.Notify.error("Fetch failed. Server reported: " + result.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
        }
        this.setState({ loading: false });
      });
    }
  }

  handleNewAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  handleUpdatedAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.TableId === e.TableId),
      1
    );
    current.push(e);
    this.setState({ currentAttachments: current });
  };
  deleteAttachment = (e: Models.IUserFormSubmissionAttachment) => {
    let current = JSON.parse(JSON.stringify(this.state.currentAttachments));
    current.splice(
      this.state.currentAttachments.findIndex((x) => x.Index === e.Index),
      1
    );
    this.setState({ currentAttachments: current });
  };

  render() {
    const schema = JSON.parse(this.props.initialNode.DataSchema);
    const uischema = JSON.parse(this.props.initialNode.DashboardUISchema);
    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading Tip...">
        <div className="form-container full-width full-height">
          <h3>Tip Management</h3>
          <h4>Current status: {UserFormSubmissionStateToString.UserFormSubmissionStateToString(this.state.editingNode.SubmissionState)}</h4>
          <div className="submissionInfoHeader">
            <span>Created on: {Convert.dateToFormattedString(this.props.initialNode.CreationDate, Languages.English)}</span>
            <span>Last modified on: {Convert.dateToFormattedString(this.props.initialNode.LastModificationDate, Languages.English)}</span>
            {this.props.initialNode.ApprovalDate !== null && (
            <span>Last approved on: {Convert.dateToFormattedString(this.props.initialNode.ApprovalDate, Languages.English)}</span>
            )}
          </div>
          <div className="tip-tabs">
            <Tabs defaultActiveKey={this.state.activeTab} id="tipTabs">
              <Tab eventKey={TipTab.Properties} title={"Properties"}>
                <div className="full-width full-height tipPropeties">
                  <h4>Created by</h4>
                  <UserInfoBlock User={this.props.initialNode.CreatedBy} />
                  {this.props.initialNode.ApprovedBy !== null && (
                    <React.Fragment>
                      <h4>Approved by</h4>
                      <UserInfoBlock User={this.props.initialNode.ApprovedBy} />
                    </React.Fragment>
                  )}
                  {this.props.initialNode.LastModifiedBy !== null && (
                    <React.Fragment>
                      <h4>Last modified by</h4>
                      <UserInfoBlock User={this.props.initialNode.LastModifiedBy} />
                    </React.Fragment>
                  )}
                  <span>
                    <span className="detailHeader">Location in content </span>
                    <span className="locationValue">{this.props.initialNode.BreadCrumb}</span>
                  </span>
                  <UserFormSubmissionContext.Provider value={this.state.editingNode}>
                    <JsonForms
                      schema={schema}
                      uischema={uischema}
                      data={this.state.submissionData === "" ? JSON.parse(this.state.editingNode.Submission) : this.state.submissionData}
                      renderers={[
                        ...materialRenderers,
                        { tester: richContentControlTester, renderer: RichContentControl },
                        { tester: tableIdWithOffsetTester, renderer: TableIdWithOffset },
                      ]}
                      cells={materialCells}
                      onChange={({ data }) => {
                        this.setState({ submissionData: data });
                      }}
                    />
                  </UserFormSubmissionContext.Provider>
                </div>
              </Tab>
              <Tab eventKey={TipTab.Titles} title={"Titles"}>
                <TitleSelector publisherId={this.props.initialNode.PublisherId} associatedTitles={this.state.currentTitles} onChange={this.titlesChanged} />
              </Tab>
              <Tab eventKey={TipTab.Attachments} title={"Attachments"}>
                <AttachmentForm
                  currentAttachments={this.state.currentAttachments}
                  parentSubmission={this.state.editingNode}
                  handleNewAttachment={this.handleNewAttachment}
                  handleUpdatedAttachment={this.handleUpdatedAttachment}
                  deleteAttachment={this.deleteAttachment}
                />
              </Tab>
            </Tabs>
            <FormGroup>
              <Col>
                <Row>
                  <Button onClick={this.saveRequested} style={{ flex: "1", marginRight: "15px" }} outline color="info">
                    Save Tip Changes
                  </Button>
                  {this.state.editingNode.SubmissionState === UserFormSubmissionState.UnderReview && (
                    <Button
                      onClick={() => this.changeTipState(UserFormSubmissionState.Published)}
                      style={{ flex: "1", marginRight: "15px" }}
                      outline
                      color="info"
                    >
                      Publish Tip
                    </Button>
                  )}
                  {(this.state.editingNode.SubmissionState === UserFormSubmissionState.Rejected ||
                   this.state.editingNode.SubmissionState === UserFormSubmissionState.New ||
                   this.state.editingNode.SubmissionState === UserFormSubmissionState.Published) && (
                    <Button
                      onClick={() => this.changeTipState(UserFormSubmissionState.UnderReview)}
                      style={{ flex: "1", marginRight: "15px" }}
                      outline
                      color="info"
                    >
                      Review Tip
                    </Button>
                  )}
                  {this.state.editingNode.SubmissionState !== UserFormSubmissionState.Rejected && (
                    <Button onClick={this.deleteRequested} style={{ flex: "1", marginLeft: "15px" }} outline color="danger">
                      Reject Tip
                    </Button>
                  )}
                </Row>
              </Col>
            </FormGroup>
          </div>
        </div>
      </Loading>
    );
  }
}
