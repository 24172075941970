import { formatDistanceToNow } from "date-fns";
import * as React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AppSession, LoginType } from "src/models/AppSession";
import { ILoginLibrary } from "src/models/dto/DashboardModels";
import { IChartDataPoint, IGraphingDateInterval } from "src/models/dto/ReportingModels";
import { IGraphingR, ReportSubType, ReportType } from "src/models/dto/ReportingRequest";
import { ActionResult } from "src/models/Result";
import { Drawer, DrawerContainer, Loading } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { DashboardView } from "src/ui/foundation/Layout";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";
import { PanelView } from "../PanelView";

export interface IReportingViewProps {
  IsLoggedIn: boolean;
}
export interface IReportingViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  allowedPubs: ILoginLibrary[];
  currentPub: ILoginLibrary | null;
  adminStatistics: Models.IAdminStatistics | null;
  publisherStatistics: Models.IPublisherStatistics | null;
  topLicensesByPublisher30d: IChartDataPoint[] | null;
  topLicensesByPublisher7d: IChartDataPoint[] | null;
  versionsByPublisher: IChartDataPoint[] | null;
  publicationsByPublisher: IChartDataPoint[] | null;
  topProducts7d: IChartDataPoint[] | null;
  topProducts30d: IChartDataPoint[] | null;
  adminStatisticsLoading: boolean;
  promoItemsLoading: boolean;
  panelDisabled: boolean;
  salesFeed: Models.IStoreListingSaleFeedViewModel[];
  totalSales: number;

  // --- Data Report state objs
  isDataReportGenerating: boolean;
  currentStartDate: Date;
  currentEndDate: Date;
  currentDataReport: ReportType;
  currentReportSubtype: ReportSubType;
  currentDataReportGraphingData: IChartDataPoint[] | null;
  currentDataReportGraphLoading: boolean;
  lastDaysValue: number;
}
interface ILabelArg {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}
export class ReportingView extends React.Component<IReportingViewProps, IReportingViewState> {
  context: AppSession;
  static contextType = AppContext;
  storeListingStatsTable = React.createRef<DataTable>();
  affiliateStatsTable = React.createRef<DataTable>();
  interval: NodeJS.Timeout | null = null;
  constructor(props: IReportingViewProps) {
    super(props);
    this.state = {
      drawerShow: false,
      currentDrawerContent: null,
      allowedPubs: [],
      currentPub: null,
      adminStatistics: null,
      publisherStatistics: null,
      topLicensesByPublisher30d: null,
      topLicensesByPublisher7d: null,
      versionsByPublisher: null,
      publicationsByPublisher: null,
      topProducts7d: null,
      topProducts30d: null,
      currentDataReport: ReportType.TitleActivityPublisher,
      currentReportSubtype: ReportSubType.None,
      currentEndDate: new Date(),
      currentStartDate: new Date(),
      isDataReportGenerating: false,
      adminStatisticsLoading: false,
      currentDataReportGraphingData: null,
      currentDataReportGraphLoading: false,
      panelDisabled: false,
      lastDaysValue: 7,
      promoItemsLoading: false,
      salesFeed: [],
      totalSales: 0,
    };
  }
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Reporting)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Reporting)!.loading.off(this.loginInit);
  }

  loginInit = () => {
    let allowedPubs = this.context.getManageableReporting();
    if (allowedPubs.length > 0) {
      this.setState({ allowedPubs: allowedPubs, currentPub: allowedPubs[0] }, () => {
        this.getPublisherStatistics();
        this.reloadGraphs();
      });
      this.context.viewedViews.get(DashboardView.Reporting)!.progressLoading();
    }
  };

  getAdminStatistics = () => {
    this.setState({ adminStatisticsLoading: true }, async () => {
      let response = await this.context.getAdminStatistics({});
      if (response.valid()) {
        this.setState({ adminStatistics: response.data.AdminStatistics });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
      this.setState({ adminStatisticsLoading: false });
    });
  };
  getPromoMailingList = () => {
    this.setState({ promoItemsLoading: true }, async () => {
      let response = await this.context.getPromoItems({});
      if (response.valid()) {
        this.downloadFile("promoMailingList.csv", response.data.PromoCsv as string);
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
      this.setState({ promoItemsLoading: false });
    });
  };
  getPublisherStatistics = () => {
    this.setState({ panelDisabled: true }, async () => {
      let response = await this.context.getPublisherStatistics({
        PublisherId: this.state.currentPub!.PublisherId,
      });
      this.setState({ panelDisabled: false });
      if (response.valid()) {
        this.setState({
          publisherStatistics: response.data.PublisherStatistics,
        });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    });
  };
  generateArbitraryReport = (extraArgs: any, interval: IGraphingDateInterval | null) => {
    this.setState({ isDataReportGenerating: true }, async () => {
      let pub = this.state.currentPub!;
      let response = await this.context.generateReport({
        ExtraArgs: extraArgs,
        GenerateCsv: true,
        Interval:
          interval === null
            ? {
                DaysPast: null,
                EndDate: this.state.currentEndDate,
                StartDate: this.state.currentStartDate,
              }
            : interval,
        PublisherId: pub.PublisherId,
        ReportSubType: this.state.currentReportSubtype,
        ReportType: this.state.currentDataReport,
      });
      if (response.valid()) {
        this.downloadFile(
          pub.DisplayName +
            " - " +
            this.getFriendlyReportName(this.state.currentDataReport) +
            " - " +
            Convert.formatDateForForm(new Date(this.state.currentStartDate)) +
            " to " +
            Convert.formatDateForForm(new Date(this.state.currentEndDate)) +
            ".csv",
          response.data.CsvData
        );
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
      this.setState({ isDataReportGenerating: false });
    });
  };
  getTopLicensesByPublisher = async (interval: IGraphingDateInterval) => {
    let response = await this.context.getTopLicensesByPublisher({
      Interval: interval,
    });
    if (response.valid()) {
      return response.data.DataPoints;
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      return null;
    }
  };
  getTopProductsForPublisher = async (publisherId: number, interval: IGraphingDateInterval) => {
    let response = await this.context.getTopProductsForPublisher({
      PublisherId: publisherId,
      Interval: interval,
    });
    if (response.valid()) {
      return response.data.DataPoints;
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      return null;
    }
  };
  getPublicationsByPublisher = async () => {
    let response = await this.context.getPublicationsByPublisher({});
    if (response.valid()) {
      return response.data.DataPoints;
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      return null;
    }
  };
  getVersionsByPublisher = async () => {
    let response = await this.context.getVersionsByPublisher({});
    if (response.valid()) {
      return response.data.DataPoints;
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      return null;
    }
  };
  getTitleActivityCsv = () => {
    this.setState({ isDataReportGenerating: true }, async () => {
      let pub = this.state.currentPub!;
      let response = await this.context.getTitleActivityCsv({
        EndDate: this.state.currentEndDate,
        PublisherId: pub.PublisherId,
        StartDate: this.state.currentStartDate,
      });
      if (response.valid()) {
        this.downloadFile(
          pub.DisplayName +
            " - Title Activity - " +
            Convert.formatDateForForm(new Date(this.state.currentStartDate)) +
            " to " +
            Convert.formatDateForForm(new Date(this.state.currentEndDate)) +
            ".csv",
          response.data.CsvData
        );
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
      this.setState({ isDataReportGenerating: false });
    });
  };
  getNewProviderAccountsCsv = () => {
    this.setState({ isDataReportGenerating: true }, async () => {
      let pub = this.state.currentPub!;
      let response = await this.context.getNewProviderAccountsCsv({
        EndDate: this.state.currentEndDate,
        PublisherId: pub.PublisherId,
        StartDate: this.state.currentStartDate,
      });
      if (response.valid()) {
        this.downloadFile(
          pub.DisplayName +
            " - New Provider Accounts - " +
            Convert.formatDateForForm(new Date(this.state.currentStartDate)) +
            " to " +
            Convert.formatDateForForm(new Date(this.state.currentEndDate)) +
            ".csv",
          response.data.CsvData
        );
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
      this.setState({ isDataReportGenerating: false });
    });
  };
  getNewLicensesCsv = () => {
    this.setState({ isDataReportGenerating: true }, async () => {
      let pub = this.state.currentPub!;
      let response = await this.context.getNewLicensesCsv({
        EndDate: this.state.currentEndDate,
        PublisherId: pub.PublisherId,
        StartDate: this.state.currentStartDate,
      });
      if (response.valid()) {
        this.downloadFile(
          pub.DisplayName +
            " - New Licenses - " +
            Convert.formatDateForForm(new Date(this.state.currentStartDate)) +
            " to " +
            Convert.formatDateForForm(new Date(this.state.currentEndDate)) +
            ".csv",
          response.data.CsvData
        );
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
      this.setState({ isDataReportGenerating: false });
    });
  };
  downloadFile = (fileName: string, data: string) => {
    let element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(data));
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  reloadGraphs = async () => {
    this.setState({
      topProducts30d: await this.getTopProductsForPublisher(this.state.currentPub!.PublisherId, {
        DaysPast: 30,
        EndDate: null,
        StartDate: null,
      } as IGraphingDateInterval),
    });
    this.setState({
      topProducts7d: await this.getTopProductsForPublisher(this.state.currentPub!.PublisherId, {
        DaysPast: 7,
        EndDate: null,
        StartDate: null,
      } as IGraphingDateInterval),
    });
    if (this.context.canManageSystem()) {
      this.setState({
        topLicensesByPublisher30d: await this.getTopLicensesByPublisher({
          DaysPast: 30,
          EndDate: null,
          StartDate: null,
        } as IGraphingDateInterval),
      });
      this.setState({
        topLicensesByPublisher7d: await this.getTopLicensesByPublisher({
          DaysPast: 7,
          EndDate: null,
          StartDate: null,
        } as IGraphingDateInterval),
      });
      this.setState({
        publicationsByPublisher: await this.getPublicationsByPublisher(),
      });
      this.setState({
        versionsByPublisher: await this.getVersionsByPublisher(),
      });
    }
  };

  publisherChanged = async (e: ILoginLibrary) => {
    await this.context.getTotalSales({ PublisherId: e.PublisherId }).then((result) => {
      this.setState({ totalSales: result.data.TotalSales });
    });
    this.setState(
      {
        currentPub: this.state.allowedPubs.find((x) => x.PublisherId === e.PublisherId)!,
        publisherStatistics: null,
      },
      () => {
        this.getPublisherStatistics();
        this.reloadGraphs();
      }
    );
    await this.updateSalesAndCreditsData(e.PublisherId);

    this.storeListingStatsTable.current!.reload();
    this.affiliateStatsTable.current!.reload();
  };
  dataReportChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentDataReport: +e.target.value,
      currentDataReportGraphLoading: false,
      currentDataReportGraphingData: null,
    });
  };
  renderCustomizedLabel = (arg: ILabelArg) => {
    const RADIAN = Math.PI / 180;
    const radius = arg.innerRadius + (arg.outerRadius - arg.innerRadius) * 0.5;
    const x = arg.cx + radius * Math.cos(-arg.midAngle * RADIAN);
    const y = arg.cy + radius * Math.sin(-arg.midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > arg.cx ? "start" : "end"} dominantBaseline="central">
        {`${(arg.percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  reloadDataReportGraph = () => {
    this.setState({ currentDataReportGraphLoading: true }, async () => {
      let response: ActionResult<IGraphingR> | null = null;
      switch (this.state.currentDataReport) {
        case ReportType.TitleActivityPublisher:
        case ReportType.NewProviderAccountsPublisher:
          response = null;
          break;
        case ReportType.NewLicensesPublisher:
          response = await this.context.getNewLicensesGraphing({
            EndDate: this.state.currentEndDate,
            PublisherId: this.state.currentPub!.PublisherId,
            StartDate: this.state.currentStartDate,
          });
          break;
        default:
          break;
      }
      if (response !== null) {
        if (response.valid()) {
          this.setState({
            currentDataReportGraphingData: response.data.DataPoints,
          });
        } else {
          if (response.errors.length > 0) {
            Messages.Notify.error("Statistics fetch failed. Server reported: " + response.errors[0].Message);
          } else {
            Messages.Notify.error("An error occurred while executing the communication");
          }
          this.setState({ currentDataReportGraphingData: null });
        }
      }
      this.setState({ currentDataReportGraphLoading: false });
    });
  };
  startDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState({
      currentStartDate: dato,
    });
  };
  endDateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    let parts = e.target.value.split("-");
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
    this.setState({
      currentEndDate: dato,
    });
  };
  getReportControls = () => {
    let startDate = Convert.formatDateForForm(new Date(this.state.currentStartDate));
    let endDate = Convert.formatDateForForm(new Date(this.state.currentEndDate));
    switch (this.state.currentDataReport) {
      case ReportType.TitleActivityPublisher:
        return (
          <div>
            <Row>
              <FormGroup style={{ marginRight: "15px" }}>
                <Label for="startDate">Starting date</Label>
                <Input type="date" name="startDate" id="startDate" value={startDate} onChange={this.startDateChanged} />
              </FormGroup>
              <FormGroup>
                <Label for="endDate">Ending date</Label>
                <Input type="date" name="endDate" id="endDate" value={endDate} onChange={this.endDateChanged} />
              </FormGroup>
            </Row>
            <Button outline color="info" onClick={this.getTitleActivityCsv}>
              Download Report
            </Button>
          </div>
        );
      case ReportType.NewProviderAccountsPublisher:
        return (
          <div>
            <Row>
              <FormGroup style={{ marginRight: "15px" }}>
                <Label for="startDate">Starting date</Label>
                <Input type="date" name="startDate" id="startDate" value={startDate} onChange={this.startDateChanged} />
              </FormGroup>
              <FormGroup>
                <Label for="endDate">Ending date</Label>
                <Input type="date" name="endDate" id="endDate" value={endDate} onChange={this.endDateChanged} />
              </FormGroup>
            </Row>
            <Button outline color="info" onClick={this.getNewProviderAccountsCsv}>
              Download Report
            </Button>
          </div>
        );
      case ReportType.UserCount:
      case ReportType.Adoption:
        return (
          <div>
            <Row>
              <FormGroup style={{ marginRight: "15px" }}>
                <Label for="startDate">Users active in N last days: </Label>
                <Input
                  type="number"
                  name="startDate"
                  id="startDate"
                  value={this.state.lastDaysValue}
                  onChange={(e) => this.setState({ lastDaysValue: Number(e.target.value) })}
                />
              </FormGroup>
            </Row>
            <Button
              outline
              color="info"
              onClick={() =>
                this.generateArbitraryReport(JSON.parse("{}"), {
                  DaysPast: this.state.lastDaysValue,
                  EndDate: null,
                  StartDate: null,
                })
              }
            >
              Download Report
            </Button>
          </div>
        );
      case ReportType.TopTitles:
        return (
          <div>
            <Row>
              <FormGroup style={{ marginRight: "15px" }}>
                <Label for="startDate">Starting date</Label>
                <Input type="date" name="startDate" id="startDate" value={startDate} onChange={this.startDateChanged} />
              </FormGroup>
              <FormGroup>
                <Label for="endDate">Ending date</Label>
                <Input type="date" name="endDate" id="endDate" value={endDate} onChange={this.endDateChanged} />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <p>Select a report subtype</p>
                <Input
                  onChange={(e) =>
                    this.setState({
                      currentReportSubtype: Number(e.target.value),
                    })
                  }
                  className={"subtypeSelect"}
                  type="select"
                  name="subtypeSelect"
                  id="subtypeSelect"
                >
                  {this.getReportSubtypeOptions()}
                </Input>
              </FormGroup>
            </Row>
            <Button outline color="info" onClick={() => this.generateArbitraryReport(JSON.parse("{}"), null)}>
              Download Report
            </Button>
          </div>
        );
      case ReportType.OperatingSystem:
        return (
          <div>
            <Row>
              <FormGroup style={{ marginRight: "15px" }}>
                <Label for="startDate">Starting date</Label>
                <Input type="date" name="startDate" id="startDate" value={startDate} onChange={this.startDateChanged} />
              </FormGroup>
              <FormGroup>
                <Label for="endDate">Ending date</Label>
                <Input type="date" name="endDate" id="endDate" value={endDate} onChange={this.endDateChanged} />
              </FormGroup>
            </Row>
            <Button outline color="info" onClick={() => this.generateArbitraryReport(JSON.parse("{}"), null)}>
              Download Report
            </Button>
          </div>
        );
      case ReportType.BookDownloads:
      case ReportType.GlobalNote:
      case ReportType.Bulletin:
      case ReportType.Feedback:
      case ReportType.Tip:
      case ReportType.Product:
      case ReportType.Version:
      case ReportType.Announcements:
      case ReportType.StoreFront:
        return (
          <div>
            <Row>
              <FormGroup style={{ marginRight: "15px" }}>
                <Label for="startDate">Starting date</Label>
                <Input type="date" name="startDate" id="startDate" value={startDate} onChange={this.startDateChanged} />
              </FormGroup>
              <FormGroup>
                <Label for="endDate">Ending date</Label>
                <Input type="date" name="endDate" id="endDate" value={endDate} onChange={this.endDateChanged} />
              </FormGroup>
            </Row>
            <Button outline color="info" onClick={() => this.generateArbitraryReport(JSON.parse("{}"), null)}>
              Download Report
            </Button>
          </div>
        );
      case ReportType.User:
        return (
          <div>
            <Row>
              <FormGroup>
                <Button outline color="info" onClick={() => this.generateArbitraryReport(JSON.parse("{}"), null)}>
                  Download Report
                </Button>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <span>
                  <b>
                    Note: Platform version entries are based on the highest Reader version logged-in with in the last 2 months. If there hasn&apos;t been a
                    login for a platform in &gt;2 months, N/A is displayed.
                  </b>
                </span>
              </FormGroup>
            </Row>
          </div>
        );
      case ReportType.NewLicensesPublisher:
        return (
          <div>
            <Row>
              <FormGroup style={{ marginRight: "15px" }}>
                <Label for="startDate">Starting date</Label>
                <Input type="date" name="startDate" id="startDate" value={startDate} onChange={this.startDateChanged} />
              </FormGroup>
              <FormGroup>
                <Label for="endDate">Ending date</Label>
                <Input type="date" name="endDate" id="endDate" value={endDate} onChange={this.endDateChanged} />
              </FormGroup>
            </Row>
            <Button outline color="info" onClick={this.reloadDataReportGraph} style={{ marginRight: "15px" }}>
              Refresh Graph
            </Button>
            <Button outline color="info" onClick={this.getNewLicensesCsv}>
              Download Report
            </Button>
            {this.state.currentDataReportGraphingData !== null && (
              <p style={{ margin: "15px" }}>
                The graph below shows a breakdown of new licenses created over the selected timespan. If the range is larger than 180 days, they will be grouped
                by month.
              </p>
            )}
          </div>
        );
      case ReportType.Permissions:
        return (
          <div>
            <Button outline color="info" onClick={() => this.generateArbitraryReport(JSON.parse("{}"), null)}>
              Download Report
            </Button>
          </div>
        );
      case ReportType.ActiveLicenses:
        return (
          <div>
            <Button outline color="info" onClick={() => this.generateArbitraryReport(JSON.parse("{}"), null)}>
              Download Report
            </Button>
          </div>
        );
    }
  };

  private initializeStoreListingStats = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowStoreListingPerformance({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0,
          });
        } else {
          reject();
        }
      });
    });

  private storeListingStatFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowStoreListingPerformance({ FlowRequest: request.Batches[0], PublisherId: this.state.currentPub!.PublisherId });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        } else {
          reject();
        }
      });
    });

  generateStoreListingStat = (n: INode) => {
    let node = n as Models.IStoreListingPerformanceViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} className="centerText" key={1} value={node.StoreListing} />);

    dataItems.push(
      <DataItem flexVal={1} key={2} className="centerText" value={node.TotalSales.toString() + " credits (USD$" + (node.TotalSales / 10).toFixed(2) + ")"} />
    );

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} />;
  };

  private initializeAffiliateStats = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowAffiliateCodePerformance({
          FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
          PublisherId: this.state.currentPub!.PublisherId,
        });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({
            nodes: Convert.indexify(result.data.FlowResponse).Nodes,
            targetSpine: 0,
          });
        } else {
          reject();
        }
      });
    });

  private affiliateStatFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>((resolve, reject) => {
      this.setState({ panelDisabled: true }, async () => {
        let result = await this.context.flowAffiliateCodePerformance({ FlowRequest: request.Batches[0], PublisherId: this.state.currentPub!.PublisherId });
        this.setState({ panelDisabled: false });
        if (result.valid()) {
          resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
        } else {
          reject();
        }
      });
    });

  generateAffiliateStat = (n: INode) => {
    let node = n as Models.IAffiliateCodePerformanceViewModel;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    dataItems.push(<DataItem flexVal={2} className="centerText" key={1} value={node.AffiliateCode} />);

    dataItems.push(
      <DataItem flexVal={1} key={2} className="centerText" value={node.TotalSales.toString() + " credits (USD$" + (node.TotalSales / 10).toFixed(2) + ")"} />
    );

    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} />;
  };

  updateSalesAndCreditsData = async (publisherId: number) => {
    await this.context.fetchSalesAndCreditsData({ PublisherId: publisherId }).then((result) => {
      this.setState({ salesFeed: result.data.SalesFeed });
      if (!this.interval) {
        this.interval = setInterval(async () => {
          if (this.context.login > LoginType.None) {
            await this.updateSalesAndCreditsData(this.state.currentPub!.PublisherId);
          }
        }, 10000);
      }
    });
  };

  convertUTCToLocalTime = (date: Date) => {
    let parts = date.toString().split(/[-T:.]/);
    let dato = new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2], +parts[3], +parts[4], +parts[5]));
    return dato;
  };

  render() {
    if (!this.props.IsLoggedIn || this.state.allowedPubs.length <= 0 || !this.context.viewedViews.get(DashboardView.Reporting)!.isLoaded()) {
      return "";
    }
    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.maxHeight = "500px";
    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          publisherList={this.state.allowedPubs}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.currentPub!}
          disabled={this.state.panelDisabled}
        />
        <div className="reportingView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({
                  drawerShow: false,
                  currentDrawerContent: null,
                });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="reportingViewInner">
              <div className="section">
                <Loading theme="opaque" status="Generating Data Report..." isLoading={this.state.isDataReportGenerating}>
                  <h1>Welcome to the Reporting View</h1>
                  <p>For each publisher you have manage rights on, you can see the statistics relating to their titles, subscriptions, etc.</p>
                  <Col>
                    <Row>
                      <Input onChange={this.dataReportChanged} className={"pubSelect"} type="select" name="publisher" id="publisher">
                        {this.getReportOptions()}
                      </Input>
                    </Row>
                    {this.getReportControls()}
                    <Loading isLoading={this.state.currentDataReportGraphLoading} theme="opaque" status="Loading data graph...">
                      {this.state.currentDataReportGraphingData !== null && (
                        <div className="graphHolder">
                          <div className="graphBox">
                            <ResponsiveContainer width="99%" height="100%" minWidth="500" minHeight="500">
                              <BarChart
                                width={500}
                                height={300}
                                data={this.state.currentDataReportGraphingData}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" tick={false} />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" name="Licenses created" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          </div>
                        </div>
                      )}
                    </Loading>
                  </Col>
                </Loading>
              </div>
              {this.context.canManageSystem() && (
                <Loading isLoading={this.state.adminStatisticsLoading} theme="opaque" status="Loading admin statistics...">
                  <div style={{ minHeight: "250px" }} className="section">
                    <h1>Administrative statistics (these are the stats for the whole system)</h1>
                    <Button onClick={this.getAdminStatistics} outline color="info">
                      Load admin stats
                    </Button>
                    <Button style={{ marginTop: "20px" }} onClick={this.getPromoMailingList} disabled={this.state.promoItemsLoading} outline color="info">
                      Download promo mailing list.
                    </Button>
                    {this.state.adminStatistics !== null && (
                      <div className="statsHolder">
                        <Col>
                          <Row>
                            <span>
                              <b>Total Auth Users:</b> {this.state.adminStatistics.AuthUserCount}
                            </span>
                            <span>
                              <b>Total Pre-Users:</b> {this.state.adminStatistics.PreUserCount}
                            </span>
                            <span>
                              <b>Total Customers:</b> {this.state.adminStatistics.CustomerCount}
                            </span>
                            <span>
                              <b>Total Users:</b> {this.state.adminStatistics.AllUserCount}
                            </span>
                            <span>
                              <b>Users created last 7 days:</b> {this.state.adminStatistics.UsersLast7Days}
                            </span>
                            <span>
                              <b>Users created last 24hrs:</b> {this.state.adminStatistics.UsersLast24Hours}
                            </span>
                          </Row>
                          <Row>
                            <span>
                              <b>Total Subscriptions:</b> {this.state.adminStatistics.AllSubscriptionCount}
                            </span>
                            <span>
                              <b>Subscriptions created last 7 days:</b> {this.state.adminStatistics.SubscriptionsLast7Days}
                            </span>
                            <span>
                              <b>Subscriptions created last 24 hours:</b> {this.state.adminStatistics.SubscriptionsLast24Hours}
                            </span>
                          </Row>
                          <Row>
                            <span>
                              <b>Total Licenses:</b> {this.state.adminStatistics.AllLicenseCount}
                            </span>
                            <span>
                              <b>Licenses created last 7 days:</b> {this.state.adminStatistics.LicensesLast7Days}
                            </span>
                            <span>
                              <b>Licenses created last 24hrs:</b> {this.state.adminStatistics.LicensesLast24Hours}
                            </span>
                          </Row>
                          <Row>
                            <span>
                              <b>Total System Events:</b> {this.state.adminStatistics.SystemEventCount}
                            </span>
                            <span>
                              <b>System Events last 7 days:</b> {this.state.adminStatistics.SystemEventsLast7Days}
                            </span>
                            <span>
                              <b>System Events last 24hrs:</b> {this.state.adminStatistics.SystemEventsLast24Hours}
                            </span>
                          </Row>
                          <Row>
                            <span>
                              <b>Total Products:</b> {this.state.adminStatistics.ProductCount}
                            </span>
                            <span>
                              <b>Total Titles:</b> {this.state.adminStatistics.TitlesCount}
                            </span>
                            <span>
                              <b>Total Versions:</b> {this.state.adminStatistics.VersionsCount}
                            </span>
                            <span>
                              <b>Pending invitations:</b> {this.state.adminStatistics.PendingInvitations}
                            </span>
                          </Row>
                        </Col>
                      </div>
                    )}
                  </div>
                  <div className="section">
                    <h1>Administrative charts</h1>
                    {this.state.adminStatistics !== null && (
                      <div className="full-width innerGraphSection">
                        <div className="graphTitles">
                          <h3 className="titleItem">Licenses by Publisher (last 30 days)</h3>
                          <h3 className="titleItem">Licenses by Publisher (last 7 days)</h3>
                        </div>
                        <div className="graphHolder">
                          <div className="graphBox">
                            {this.state.topLicensesByPublisher30d !== null && (
                              <ResponsiveContainer width="99%" height="100%" minWidth="300" minHeight="300">
                                <BarChart
                                  width={500}
                                  height={300}
                                  data={this.state.topLicensesByPublisher30d}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" tick={false} />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="value" name="Licenses created" fill="#8884d8" />
                                </BarChart>
                              </ResponsiveContainer>
                            )}
                          </div>
                          <div className="graphBox">
                            {this.state.topLicensesByPublisher7d !== null && (
                              <ResponsiveContainer width="99%" height="100%" minWidth="300" minHeight="300">
                                <BarChart
                                  width={500}
                                  height={300}
                                  data={this.state.topLicensesByPublisher7d}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" tick={false} />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="value" name="Licenses created" fill="#8884d8" />
                                </BarChart>
                              </ResponsiveContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.adminStatistics !== null && (
                      <div className="full-width innerGraphSection">
                        <div className="graphTitles">
                          <h3 className="titleItem">Publications by Publisher</h3>
                          <h3 className="titleItem">Versions by publisher</h3>
                        </div>
                        <div className="graphHolder">
                          <div className="graphBox">
                            {this.state.publicationsByPublisher !== null && (
                              <ResponsiveContainer width="99%" height="100%" minWidth="300" minHeight="300">
                                <BarChart
                                  width={500}
                                  height={300}
                                  data={this.state.publicationsByPublisher}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" tick={false} />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="value" name="Publications" fill="#8884d8" />
                                </BarChart>
                              </ResponsiveContainer>
                            )}
                          </div>
                          <div className="graphBox">
                            {this.state.versionsByPublisher !== null && (
                              <ResponsiveContainer width="99%" height="100%" minWidth="300" minHeight="300">
                                <BarChart
                                  width={500}
                                  height={300}
                                  data={this.state.versionsByPublisher}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" tick={false} />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="value" name="Versions" fill="#8884d8" />
                                </BarChart>
                              </ResponsiveContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Loading>
              )}
              <Loading isLoading={this.state.publisherStatistics === null} theme="opaque" status="Loading publisher statistics...">
                <div style={{ minHeight: "250px" }} className="section">
                  <h1>Publisher statistics ({this.state.currentPub!.DisplayName})</h1>
                  {this.state.publisherStatistics !== null && (
                    <div className="statsHolder">
                      <Col>
                        <Row>
                          <span>
                            <b>Total Customers:</b> {this.state.publisherStatistics.CustomerCount}
                          </span>
                          <span>
                            <b>Total Subscriptions:</b> {this.state.publisherStatistics.AllSubscriptionCount}
                          </span>
                          <span>
                            <b>Subscriptions created last 7 days:</b> {this.state.publisherStatistics.SubscriptionsLast7Days}
                          </span>
                          <span>
                            <b>Subscriptions created last 24 hours:</b> {this.state.publisherStatistics.SubscriptionsLast24Hours}
                          </span>
                        </Row>
                        <Row>
                          <span>
                            <b>Total Licenses:</b> {this.state.publisherStatistics.AllLicenseCount}
                          </span>
                          <span>
                            <b>Licenses created last 7 days:</b> {this.state.publisherStatistics.LicensesLast7Days}
                          </span>
                          <span>
                            <b>Licenses created last 24hrs:</b> {this.state.publisherStatistics.LicensesLast24Hours}
                          </span>
                          <span>
                            <b>Total store listings sold last 7 days:</b> {this.state.publisherStatistics.StoreListingsSoldLast7Days}
                          </span>
                        </Row>
                        <Row>
                          <span>
                            <b>Total Products:</b> {this.state.publisherStatistics.ProductCount}
                          </span>
                          <span>
                            <b>Total Titles:</b> {this.state.publisherStatistics.TitlesCount}
                          </span>
                          <span>
                            <b>Total Versions:</b> {this.state.publisherStatistics.VersionsCount}
                          </span>
                          <span>
                            <b>Total Announcements:</b> {this.state.publisherStatistics.AnnouncementsCount}
                          </span>
                        </Row>
                      </Col>
                    </div>
                  )}
                </div>
                <div className="section">
                  <h1>Publisher charts</h1>
                  {this.state.publisherStatistics !== null && (
                    <div className="full-width innerGraphSection">
                      <div className="graphTitles">
                        <div className="titleItem">
                          <h3>Top 10 Best products (last 30 days)</h3>
                        </div>
                        <div className="titleItem">
                          <h3>Top 10 Best products (last 7 days)</h3>
                        </div>
                      </div>
                      <div className="graphDescription">
                        <p>Displays the top 10 products that have had the most licenses created for them.</p>
                      </div>
                      <div className="graphHolder">
                        <div className="graphBox">
                          {this.state.topProducts30d !== null && (
                            <ResponsiveContainer width="99%" height="100%" minWidth="300px" minHeight="300px">
                              <BarChart
                                width={500}
                                height={300}
                                data={this.state.topProducts30d}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" tick={false} />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" name="Licenses created" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          )}
                        </div>
                        <div className="graphBox">
                          {this.state.topProducts7d !== null && (
                            <ResponsiveContainer width="99%" height="100%" minWidth="300px" minHeight="300px">
                              <BarChart
                                width={500}
                                height={300}
                                data={this.state.topProducts7d}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" tick={false} />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" name="Licenses created" fill="#8884d8" />
                              </BarChart>
                            </ResponsiveContainer>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {this.context.canManageSystem() && (
                  <div className="section">
                    <h1>Publisher Store Sales Overview</h1>
                    <div>Total Sales: {this.state.totalSales + " credits (USD$" + (this.state.totalSales / 10).toFixed(2) + ")"}</div>
                    <DataTable
                      headers={["Store Listing", "Sales"]}
                      headerFlexes={[2, 1]}
                      flowProvider={this.storeListingStatFlowProvider}
                      initializeFlowProvider={this.initializeStoreListingStats}
                      objectBuilder={this.generateStoreListingStat}
                      ref={this.storeListingStatsTable}
                      settingsOverride={settings}
                    />
                    <DataTable
                      headers={["Affiliate Code", "Sales"]}
                      headerFlexes={[2, 1]}
                      flowProvider={this.affiliateStatFlowProvider}
                      initializeFlowProvider={this.initializeAffiliateStats}
                      objectBuilder={this.generateAffiliateStat}
                      ref={this.affiliateStatsTable}
                      settingsOverride={settings}
                    />

                    <h2>Recent Purchases</h2>
                    <ul>
                      <li className="recentPurchasesHeader">
                        <span className="listingHeader">Listing</span>
                        <span className="creditsHeader">Price</span>
                        <span className="purchasedHeader">Purchased</span>
                      </li>
                      {this.state.salesFeed.map((sale, index) => (
                        <li key={index} className="saleItem">
                          <span className="listingName">{sale.Listing}</span>
                          <span className="credits">
                            {sale.Credits} credits (USD${(sale.Credits / 10).toFixed(2)})
                          </span>
                          <span className="timeAgo">
                            {formatDistanceToNow(this.convertUTCToLocalTime(sale.PurchaseDate), { addSuffix: true, includeSeconds: true })}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </Loading>
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
  getFriendlyReportName = (type: ReportType) => {
    switch (type) {
      case ReportType.Adoption:
        return "Adoption rate report";
      case ReportType.BookDownloads:
        return "User book consumption report";
      case ReportType.Bulletin:
        return "Bulletin report";
      case ReportType.GlobalNote:
        return "Global Note Report";
      case ReportType.Feedback:
        return "Feedback report";
      case ReportType.NewLicensesPublisher:
        return "New licenses report";
      case ReportType.NewProviderAccountsPublisher:
        return "New provider accounts report";
      case ReportType.OperatingSystem:
        return "Operating system report";
      case ReportType.Product:
        return "Library content report";
      case ReportType.Tip:
        return "Tip report";
      case ReportType.TitleActivityPublisher:
        return "Title activity report";
      case ReportType.TopTitles:
        return "Top titles report";
      case ReportType.User:
        return "User report";
      case ReportType.UserCount:
        return "User counts report";
      case ReportType.Version:
        return "Book version report";
      case ReportType.Permissions:
        return "User permissions report";
      case ReportType.ActiveLicenses:
        return "Active Licenses Report";
      case ReportType.Announcements:
        return "Announcements Report";
      case ReportType.StoreFront:
        return "Storefront Report";
    }
  };
  getFriendlyReportSubtypeName = (type: ReportSubType) => {
    switch (type) {
      case ReportSubType.EmployeeType:
        return "Employee Type";
      case ReportSubType.EmployeeTypeAndGeography:
        return "Employee Type And Geography";
      case ReportSubType.Geography:
        return "Geography";
      case ReportSubType.None:
        return "None";
    }
  };
  getReportOptions = () => {
    let retSet = [];
    for (let item in ReportType) {
      if (!isNaN(Number(item))) {
        let type: ReportType = Number(item);
        retSet.push(
          <option value={type} key={type} data-providerval={type}>
            {this.getFriendlyReportName(type)}
          </option>
        );
      }
    }
    return retSet;
  };
  getReportSubtypeOptions = () => {
    let retSet = [];
    for (let item in ReportSubType) {
      if (!isNaN(Number(item))) {
        let type: ReportSubType = Number(item);
        retSet.push(
          <option value={type} key={type} data-providerval={type}>
            {this.getFriendlyReportSubtypeName(type)}
          </option>
        );
      }
    }
    return retSet;
  };
}
