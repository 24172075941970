import { IFlow } from './AppRequest';
import {
    IAnnouncement, IAnnouncementAttachment, IAnnouncementComment, IAnnouncementViewModel
} from './DashboardModels';

// import { IAnnouncement } from './DashboardModels';

export interface IFlowAnnouncementsRequest extends IFlow {
  PublisherId: number;
  TitleId: number;
}

export interface IFlowCommentsForAnnouncementRequest extends IFlow {
  AnnouncementId: number;
}

export interface IGetFullAnnouncement {
  AnnouncementId: number;
}

export interface IGetFullAnnouncementR {
  Announcement: IAnnouncementViewModel;
}

export interface IGetFullAnnouncementR {
  Announcement: IAnnouncementViewModel;
}

export interface IInsertOrUpdateAnnouncement {
  Announcement: IAnnouncement;
  Attachments: IAnnouncementAttachment[];
  AllTags: string[];
  SelectedTags: string[];
  Titles: number[];
}

export interface IInsertOrUpdateAnnouncementR {
  Announcement: IAnnouncementViewModel;
}

export interface IChangeCommentState {
  TableId: number;
  NewState: number;
}

export interface IChangeCommentStateR {
  Comment: IAnnouncementComment;
}

export interface IDeleteAnnouncement {
  TableId: number;
}

export interface IDeleteAnnouncementR {}

export interface IGetAnnouncementAttachments {
  TableId: number;
}

export interface IGetAnnouncementAttachmentsR {
  Attachments: IAnnouncementAttachment[];
}

export enum CommentState {
  NeedsReview,
  Published,
  Rejected
}
