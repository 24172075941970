import * as React from "react";
import { Button } from "reactstrap";
import { AppSession } from "src/models/AppSession";

import { FormGroup } from "@mui/material";

import { Loading } from "../foundation/Controls";
import * as Messages from "../foundation/Messages";
import { AppContext } from "../state/Contextes";

export interface IAccountConfirmViewProps {
  userCode: string;
  clearInvite: () => void;
}
export interface IAccountConfirmViewState {
  accountConfirmed: boolean;
}

export class AccountConfirmView extends React.Component<IAccountConfirmViewProps, IAccountConfirmViewState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IAccountConfirmViewProps) {
    super(props);
    this.state = { accountConfirmed: false };
  }
  async componentDidMount() {
    let response = await this.context.confirmUserAccount({ UserCode: this.props.userCode });
    if (response.valid()) {
      this.setState({ accountConfirmed: true });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Confirmation failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
      this.props.clearInvite();
    }
  }
  openProLibro = () => {
    window.open("https://link.prolibro.com/prolibro", "_blank");
  };
  render() {
    return (
      <div className="darkOverlay">
        <div className="accountConfirmView">
          <Loading theme="opaque" isLoading={this.state.accountConfirmed === false} status="Confirming account...">
            <div className="confirmContainer">
              <div className="confirmText">
                <h1>Your account was confirmed successfully!</h1>
              </div>
              <FormGroup>
                <Button color="primary" outline onClick={this.openProLibro} style={{ marginBottom: "15px" }}>
                  Login to proLibro
                </Button>
                <Button color="primary" outline onClick={this.props.clearInvite}>
                  Login to the Dashboard
                </Button>
              </FormGroup>
            </div>
          </Loading>
        </div>
      </div>
    );
  }
}
