import * as React from 'react';
import SVG from 'react-inlinesvg';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Row } from 'reactstrap';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { AuthProviderType } from 'src/models/dto/DashboardModels';
import { Resource } from 'src/models/Resource';
import { Wire } from 'src/network/Wire';
import { SSOHookMiddleWare } from 'src/SSOHookMiddleWare';
import { SSOSignInButton } from 'src/ui/foundation/Controls/SSOSignInButton';
import { FormState } from 'src/utilities/FormState';
import { Validator } from 'src/utilities/Validator';

import ms_signin_light_en_ca from '../../../assets/img/ms_signin_light_en_ca.svg';
import ms_signin_light_fr_ca from '../../../assets/img/ms_signin_light_fr_ca.svg';
import { Image } from '../../foundation/Assets';
import { Icon, Loading } from '../../foundation/Controls';
import * as Messages from '../../foundation/Messages';
import { AppContext } from '../../state/Contextes';
import { RegisterForm } from './RegisterForm';
import { ResetForm } from './ResetForm';

interface ILoginViewProps {
  vanillaRegistrationRequested: boolean;
}

interface ILoginViewState {
  form: FormState;
  loading: boolean;
  loginMode: LoginMode;
}
enum LoginMode {
  Login,
  Register,
  ResetPassword,
}
export class LoginView extends React.Component<ILoginViewProps, ILoginViewState> {
  context: AppSession;
  static contextType = AppContext;
  loginResource: Resource;
  mounted = false;
  constructor(props: ILoginViewProps | Readonly<ILoginViewProps>) {
    super(props);
    this.ssoLogin = this.ssoLogin.bind(this);
    let form = new FormState({
      username: "",
      password: "",
      brandKey: "",
      rememberMe: true,
    });
    this.state = {
      form,
      loading: false,
      loginMode: this.props.vanillaRegistrationRequested ? LoginMode.Register : LoginMode.Login,
    };
    this.handleInput = this.handleInput.bind(this);
    this.loading = this.loading.bind(this);
    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    this.mounted = true;
    let sorted = this.context.loginLibraries.get("prolibro");
    if (!sorted) {
      sorted = this.context.loginLibraries.rows().sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))[0];
    }
    let form = new FormState({
      username: "",
      password: "",
      brandKey: sorted.BrandKey,
      rememberMe: true,
    });
    form.addValidator("username", Validator.notNullOrWhitespace, "");
    form.addValidator("password", Validator.notNullOrWhitespace, "");
    this.setState({ form: form });
    this.loginResource = new Resource(Wire.shield(this.context.wire), -1);
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  handleInput(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, type, value, checked } = event.target;
    this.setState({
      form: this.state.form.change({ name, type, value: type === "checkbox" ? checked : value }),
    });
  }

  loading(isLoading: boolean) {
    this.setState({
      loading: isLoading,
    });
  }
  async ssoLogin(username = "", password = "") {
    this.loading(true);
    let result = await this.context.ssoLogin(username, password, window.innerWidth, window.innerHeight, false);
    if (result.valid()) {
      Messages.Notify.success(this.context.localization.currentLocale.LoginView.LABEL_LOGINSUCCESS);
    } else {
      Messages.Notify.error(result.errors[0].Message);
      this.loading(false);
    }
  }

  async submit(event: any) {
    event.preventDefault();
    let form = this.state.form;
    if (form.formValid()) {
      this.loading(true);
      let result = await this.context.loginDashboard({
        brandKey: form.values.brandKey,
        password: form.values.password,
        username: form.values.username,
        rememberMe: false,
        width: window.innerWidth,
        height: window.innerHeight,
        isAnonymous: false,
      });
      if (result.valid()) {
        Messages.Notify.success(this.context.localization.currentLocale.LoginView.LABEL_LOGINSUCCESS);
        form.values.password = "";
        form.values.username = "";
        if (this.mounted) {
          this.setState({
            form: form,
            loading: false,
          });
        }
      } else {
        if (result.errors.length > 0) {
          Messages.Notify.error("Log in failed. Server reported: " + result.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
        this.loading(false);
      }
    } else {
      if (this.mounted) {
        this.setState({
          form: form,
        });
      }
    }
  }
  doRegister = () => {
    let conf = this.context.loginLibraries.get(this.state.form.values.brandKey as string);
    if (conf!.AuthorizationId === AuthProviderType.AuthSyncServer) {
      this.setState({ loginMode: LoginMode.Register });
    } else {
      this.loginResource.openExternalWebsite(
        conf!.Internationalizations && conf!.Internationalizations[this.context.localization.currentCulture - 1].RegistrationUrl
      );
    }
  };
  doReset = () => {
    let conf = this.context.loginLibraries.get(this.state.form.values.brandKey as string);
    if (conf!.AuthorizationId === AuthProviderType.AuthSyncServer) {
      this.setState({ loginMode: LoginMode.ResetPassword });
    } else {
      this.loginResource.openExternalWebsite(
        conf!.Internationalizations && conf!.Internationalizations[this.context.localization.currentCulture - 1].ResetPasswordUrl
      );
    }
  };
  render() {
    let form = this.state.form;
    let loginPlaceholder = "";
    if (!loginPlaceholder || loginPlaceholder.length === 0) {
      loginPlaceholder = this.context.localization.currentLocale.LoginView.LABEL_EMAIL;
    }
    let usernameType = "email" as any;

    let SSOButton = null;
    SSOButton =
      this.context.localization.currentCulture === Languages.French ? (
        <i className="ssoLoginButtonImage">
          <SVG src={ms_signin_light_fr_ca} />
        </i>
      ) : (
        <i className="ssoLoginButtonImage">
          <SVG src={ms_signin_light_en_ca} />
        </i>
      );
    SSOButton = (
      <div>
        <SSOSignInButton>{SSOButton}</SSOSignInButton>
        <SSOHookMiddleWare loginNeeded={this.ssoLogin} />
      </div>
    );

    let currentConf = this.context.loginLibraries.get(this.state.form.values.brandKey as string);
    if (!currentConf) {
      return "";
    }

    if (!currentConf.IsLoginEmailBased) {
      usernameType = "text" as any;
    }

    let intern = currentConf.Internationalizations && currentConf.Internationalizations[this.context.localization.currentCulture - 1];
    if (!intern) {
      return "";
    }
    let currentForm: JSX.Element;
    if (this.state.loginMode === LoginMode.Register) {
      currentForm = (
        <RegisterForm
          publisherId={this.context.loginLibraries.get(this.state.form.values.brandKey as string)!.PublisherId}
          returnToLogin={() => {
            this.setState({ loginMode: LoginMode.Login });
          }}
        />
      );
    } else if (this.state.loginMode === LoginMode.ResetPassword) {
      currentForm = (
        <ResetForm
          publisherId={this.context.loginLibraries.get(this.state.form.values.brandKey as string)!.PublisherId}
          returnToLogin={() => {
            this.setState({ loginMode: LoginMode.Login });
          }}
        />
      );
    } else {
      currentForm = (
        <Form className="p-2" onSubmit={this.submit}>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.card />} />
              </InputGroupText>

              <Input
                type={usernameType}
                name="username"
                value={form.values.username}
                invalid={form.invalid("username")}
                placeholder={loginPlaceholder}
                onChange={this.handleInput}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupText>
                <Icon src={<Image.lock />} />
              </InputGroupText>

              <Input
                type="password"
                name="password"
                value={form.values.password}
                invalid={form.invalid("password") && form.values.password !== ""}
                placeholder={this.context.localization.currentLocale.LoginView.LABEL_LOGIN_PASSWORD}
                onChange={this.handleInput}
              />
            </InputGroup>
          </FormGroup>
          {this.context.loginLibraries.rows().length > 1 && (
            <FormGroup className="">
              <InputGroup>
                <InputGroupText>
                  <Icon src={<Image.book />} />
                </InputGroupText>
                <Input title="brandKey" type="select" name="brandKey" value={form.values.brandKey as string} onChange={this.handleInput}>
                  {this.context.loginLibraries
                    .rows()
                    .sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))
                    .map((lib) => (
                      <option value={lib.BrandKey} key={lib.BrandKey}>
                        {lib.DisplayName}
                      </option>
                    ))}
                </Input>
              </InputGroup>
            </FormGroup>
          )}
          <Row>
            <Col xs={6}>
              {intern.ResetPasswordUrl !== null && intern.ResetPasswordUrl.length > 0 && (
                <FormGroup className="text-left">
                  <Button onClick={this.doReset} color="link">
                    {this.context.localization.currentLocale.LoginView.LABEL_FORGOTPASSWORD}
                  </Button>
                </FormGroup>
              )}
            </Col>
            <Col xs={6}>
              {intern.RegistrationUrl !== null && intern.RegistrationUrl.length > 0 && (
                <FormGroup className="text-right">
                  <Button onClick={this.doRegister} color="link">
                    {this.context.localization.currentLocale.LoginView.LABEL_LOGIN_SIGNUP}
                  </Button>
                </FormGroup>
              )}
            </Col>
          </Row>
          <FormGroup>
            <Button color="primary" outline block disabled={!form.formValid()}>
              {this.context.localization.currentLocale.LoginView.LABEL_LOGIN}
            </Button>
            {SSOButton}
          </FormGroup>
        </Form>
      );
    }

    return (
      <Loading isLoading={this.state.loading} className="loginView p-3">
        <img
          title="loginLogo"
          style={{ width: "100%", maxHeight: "300px" }}
          src={"data:image/png;base64," + (currentConf === undefined ? "" : currentConf.LoginLogo)}
        />
        {currentForm}
      </Loading>
    );
  }
}
