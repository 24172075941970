import * as React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { AppSession } from 'src/models/AppSession';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
// import { AboutInfoForm } from "./AboutInfosForm";
import { Action, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';
import { AboutInfoForm } from './AboutInfosForm';
import { LibraryInternalizationForm } from './LibraryInternalizationForm';

export interface ILibraryInternationalizationViewProps {
  initialNode: Models.ILibraryInternationalizationViewModel;
  // aboutInfo: Models.IAboutInfoViewModel;
  deleteRequested?: (node: Models.ILibraryInternationalizationViewModel) => void;
  saveRequested?: (node: Models.ILibraryInternationalizationViewModel) => void;
  reloadPermissions: () => void;
}
export interface ILibraryInternationalizationViewState {
  editingNode: Models.ILibraryInternationalizationViewModel;
  selectedInternationalization: number;
  aboutInfoFormOpen: boolean;
  aboutInfo: Models.IAboutInfoViewModel;
}

export class LibraryInternationalizationView extends React.Component<ILibraryInternationalizationViewProps, ILibraryInternationalizationViewState> {
  context: AppSession;
  static contextType = AppContext;
  initialNode: Models.ILibraryInternationalizationViewModel;
  // aboutInfo: Models.IAboutInfoViewModel;
  deleteRequested?: (node: Models.ILibraryInternationalizationViewModel) => void;
  saveRequested?: (node: Models.ILibraryInternationalizationViewModel, info: Models.IAboutInfoViewModel) => void;
  reloadPermissions: () => void;
  aboutInfoTable = React.createRef<DataTable>();

  constructor(props: ILibraryInternationalizationViewProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      selectedInternationalization: this.props.initialNode.LibraryInternationalization.TableId,
      aboutInfoFormOpen: false,
      aboutInfo: {
        AboutInfo: {
          Title: "",
          Html: "",
          LibraryInternationalizationTableId: this.props.initialNode.LibraryInternationalization.TableId,
          TableId: 0,
        } as Models.IAboutInfo,
      } as Models.IAboutInfoViewModel,
    };
  }

  private initializeAboutInfo = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowAboutInfos({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        InternationalizationId: this.state.selectedInternationalization,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private saveAboutInfo = async (e: Models.IAboutInfoViewModel) => {
    let response = await this.context.insertOrUpdateAboutInfo({ AboutInfo: e.AboutInfo });
    if (response.valid()) {
      Messages.Notify.success(`The Internationalization Information was saved successfully!`);
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private deleteAboutInfo = async (e: Models.IAboutInfoViewModel) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete this Library Internationalization?`);
    if (result === "true") {
      let response = await this.context.deleteAboutInfo({ AboutInfo: e.AboutInfo });
      if (response.valid()) {
        Messages.Notify.success(`The Internationalization Information item was deleted successfully!`);
        this.setState({ aboutInfoFormOpen: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };

  private aboutInfoFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowAboutInfos({ FlowRequest: request.Batches[0], InternationalizationId: this.state.selectedInternationalization });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private aboutInfoInsertRequest = () => {
    // let blankModel: Models.IAboutInfoViewModel = {
    //     AboutInfo: {
    //         Title: "",
    //         Html: "",
    //         TableId: -1,
    //         LibraryInternationalizationId: this.initialNode.LibraryInternationalization.TableId,
    //     },
    //     Index: -1,
    //     IsFirst: false,
    //     IsLast: false,
    // };
    this.setState({
      aboutInfo: {
        AboutInfo: {
          Title: "",
          Html: "",
          TableId: 0,
          LibraryInternationalizationTableId: this.props.initialNode.LibraryInternationalization.TableId,
        },
        Index: -1,
        IsFirst: false,
        IsLast: false,
      } as Models.IAboutInfoViewModel,
      aboutInfoFormOpen: true,
    });
  };
  private generateAboutInfo = (about: INode): JSX.Element => {
    // let about = node as Models.IAboutInfoViewModel;
    let attrs = {};
    /* attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = about.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = about.Index;*/
    let dataItems = [];
    dataItems.push(<DataItem flexVal={2} key={1} value={about.AboutInfo.Title} />);
    dataItems.push(<DataItem flexVal={2} key={2} className="leftBorder" value={about.AboutInfo.Html} />);

    return <DataRow node={about} key={about.AboutInfo.TableId} attributes={attrs} dataItems={dataItems} rowEditRequested={this.aboutInfoEditRequest} />;
  };
  // private aboutInfoEditRequest = (e: Models.IAboutInfoViewModel) => {
  private aboutInfoEditRequest = (eNode: INode) => {
    let e = eNode as Models.IAboutInfoViewModel;
    this.setState({
      aboutInfoFormOpen: true,
      aboutInfo: e,
    });
  };
  private aboutInfoQueryExecute = (query: string) => {
    this.aboutInfoTable.current!.reload(query);
  };
  render() {
    let settings = Models.genericDataSettings;
    settings.batchSize = 25;
    return (
      <div className="section" style={{ padding: "0" }}>
        <Tabs defaultActiveKey="inter-config" id="uncontrolled-tab-example">
          <Tab eventKey="inter-config" title="Internationalization">
            {
              <LibraryInternalizationForm
                initialNode={this.props.initialNode}
                deleteRequested={this.props.deleteRequested}
                saveRequested={this.props.saveRequested}
                reloadPermissions={this.props.reloadPermissions}
              />
            }
          </Tab>
          <Tab eventKey="info-config" title="About Infos" style={{ padding: "0.2rem" }}>
            <div className="headerAboutInfo">
              <h2>About Infos</h2>
              <p>These appear as info links on the Reader options panel. Link to your site or to resources you control.</p>
            </div>
            {!this.state.aboutInfoFormOpen ? (
              <DataTable
                headers={["Title", "Html"]}
                headerFlexes={[2, 2]}
                rowAddRequested={this.aboutInfoInsertRequest}
                flowProvider={this.aboutInfoFlowProvider}
                initializeFlowProvider={this.initializeAboutInfo}
                objectBuilder={this.generateAboutInfo}
                ref={this.aboutInfoTable}
                settingsOverride={settings}
                searchQueryComitted={this.aboutInfoQueryExecute}
              />
            ) : (
              <AboutInfoForm
                initialNode={this.state.aboutInfo}
                internationalization={this.props.initialNode}
                saveRequested={this.saveAboutInfo}
                deleteRequested={this.deleteAboutInfo}
                dismissRequested={() => this.setState({ aboutInfoFormOpen: false })}
              />
            )}
          </Tab>
        </Tabs>
      </div>
    );
  }
}
