import * as React from 'react';
// import { Tab, Tabs } from 'react-bootstrap';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { AppSession } from 'src/models/AppSession';
import { AppContext } from 'src/ui/state/Contextes';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';
import { AboutInfoForm } from './AboutInfosForm';

export interface ILibraryInternalizationFormProps {
  initialNode: Models.ILibraryInternationalizationViewModel;
  // aboutInfo: Models.IAboutInfoViewModel;
  deleteRequested?: (node: Models.ILibraryInternationalizationViewModel) => void;
  saveRequested?: (node: Models.ILibraryInternationalizationViewModel) => void;
  reloadPermissions: () => void;
}
export interface ILibraryInternalizationFormState {
  editingNode: Models.ILibraryInternationalizationViewModel;
  selectedInternationalization: number;
}
export class LibraryInternalizationForm extends React.Component<ILibraryInternalizationFormProps, ILibraryInternalizationFormState> {
  constructor(props: ILibraryInternalizationFormProps) {
    super(props);
  }
  componentDidMount() {}

  render() {
    return (
      <div className="formContainer" style={{padding:"0.2rem"}}>
        <h2>Library Internationalizations Management</h2>
        {this.props.deleteRequested ? (
          <p>You have selected the library internationalization to consult or edit.</p>
        ) : (
          <p>You have chosen to create a new library internationalization. Enter the properties below and select a means of saving.</p>
        )}
        {this.props.deleteRequested ? (
          <EditLibraryInternationalizationForm
            initialNode={this.props.initialNode}
            // aboutInfo={this.props.aboutInfo}
            deleteRequested={this.props.deleteRequested}
            saveRequested={this.props.saveRequested}
          />
        ) : (
          <CreateLibraryInternationalizationForm
            initialNode={this.props.initialNode}
            // aboutInfo={this.props.aboutInfo}
            saveRequested={this.props.saveRequested}
            reloadPermissions={this.props.reloadPermissions}
          />
        )}
      </div>
    );
  }
}
export interface IEditLibraryInternationalizationFormProps {
  initialNode: Models.ILibraryInternationalizationViewModel;
  // aboutInfo: Models.IAboutInfoViewModel;
  deleteRequested?: (node: Models.ILibraryInternationalizationViewModel) => void;
  saveRequested?: (node: Models.ILibraryInternationalizationViewModel) => void;
  saveAboutInfoRequested?: (node: Models.IAboutInfoViewModel) => void;
}
export interface IEditLibraryInternationalizationFormState {
  editingNode: Models.ILibraryInternationalizationViewModel;
  // aboutInfo: Models.IAboutInfoViewModel;
  selectedInternationalization: number;
  currentDrawerContent: JSX.Element | null;
  aboutInfoMode: boolean;
}
export class EditLibraryInternationalizationForm extends React.Component<IEditLibraryInternationalizationFormProps, IEditLibraryInternationalizationFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IEditLibraryInternationalizationFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      // aboutInfo: this.props.aboutInfo,
      selectedInternationalization: 0,
      currentDrawerContent: null,
      aboutInfoMode: false,
    };
  }
  nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          Name: e.target.value,
        },
      },
    }));
  };
  passwordUrlChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          ResetPasswordUrl: e.target.value,
        },
      },
    }));
  };
  registrationUrlChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          RegistrationUrl: e.target.value,
        },
      },
    }));
  };
  accountNotActiveMessageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          AccountNotActiveMessage: e.target.value,
        },
      },
    }));
  };
  libraryNoTitlesMessageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          LibraryNoTitlesMessage: e.target.value,
        },
      },
    }));
  };
  licenseAgreementLinkChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          LicenseAgreementLink: e.target.value,
        },
      },
    }));
  };
  privacyPolicyLinkChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          PrivacyPolicyLink: e.target.value,
        },
      },
    }));
  };
  languageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          Language: +e.target.value,
        },
      },
    }));
  };
  loginFlavourTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          LoginFlavourText: e.target.value,
        },
      },
    }));
  };

  loginCustomPlaceholderChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          LoginCustomPlaceholder: e.target.value,
        },
      },
    }));
  };
  handleAboutJsonUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkFileType(e)) {
      if (files && files.length > 0) {
        let reader = new FileReader();
        reader.readAsText(files[0]);

        reader.onload = () => {
          let basedata = reader.result;
          if (basedata === null) {
            return;
          }
          basedata = basedata.toString();
          let baseDataString = basedata;
          this.setState((prevState) => ({
            editingNode: {
              ...prevState.editingNode,
              LibraryInternationalization: {
                ...prevState.editingNode.LibraryInternationalization,
                AboutJson: baseDataString,
              },
            },
          }));
        };
        reader.onerror = (error) => error;
        Messages.Notify.success("File uploaded successfully");
      }
    }
  };
  checkFileType = (event: any) => {
    // getting file object
    let files = event.target.files;
    // define message container
    let err = "";
    // list allow mime type
    const types = ["application/json"];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err = "Invalid file type (expected file type is .json)";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      Messages.Notify.error(err);
      return false;
    }
    return true;
  };
  handleAboutHtmlUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkFileType(e)) {
      if (files && files.length > 0) {
        let reader = new FileReader();
        reader.readAsText(files[0]);

        reader.onload = () => {
          let basedata = reader.result;
          if (basedata === null) {
            return;
          }
          basedata = basedata.toString();
          let baseDataString = basedata;
          this.setState((prevState) => ({
            editingNode: {
              ...prevState.editingNode,
              LibraryInternationalization: {
                ...prevState.editingNode.LibraryInternationalization,
                AboutHtml: baseDataString,
              },
            },
          }));
        };
        reader.onerror = (error) => error;
        Messages.Notify.success("File uploaded successfully");
      }
    }
  };

  private saveAboutInfo = async (e: Models.IAboutInfoViewModel) => {
    let response = await this.context.insertOrUpdateAboutInfo({ AboutInfo: e.AboutInfo });
    if (response.valid()) {
      Messages.Notify.success(`The Internationalization Information was saved successfully!`);
      this.setState({ aboutInfoMode: false });
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private deleteAboutInfo = async (e: Models.IAboutInfoViewModel) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete this Library Internationalization?`);
    if (result === "true") {
      let response = await this.context.deleteAboutInfo({ AboutInfo: e.AboutInfo });
      if (response.valid()) {
        Messages.Notify.success(`The Internationalization Information item was deleted successfully!`);
        this.setState({ aboutInfoMode: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };


  formValid = () => {
    if (this.state.editingNode.LibraryInternationalization.Name === null) {
      return false;
    }

    return true;
  };

  render() {
    if (this.state.aboutInfoMode === true) {
      // if(this.props.aboutInfo !== undefined)
      // {
      //   return (
      //     <AboutInfoForm
      //       initialNode={{
      //         AboutInfo: this.props.aboutInfo.AboutInfo,
      //         Index: -1,
      //         IsFirst: false,
      //         IsLast: false,
      //       }}
      //       internationalization={this.state.editingNode}
      //       saveRequested={this.saveAboutInfo}
      //       deleteRequested={this.deleteAboutInfo}
      //       dismissRequested={() => this.setState({aboutInfoMode: false})}
      //     />
      //   );
      // }
      // else
      // {
        return (
          <AboutInfoForm
            initialNode={{
              AboutInfo: { Html: "", Title: "", LibraryInternationalizationTableId: this.state.editingNode.LibraryInternationalization.TableId, TableId: 0 },
              Index: -1,
              IsFirst: false,
              IsLast: false,
            }}
            internationalization={this.state.editingNode}
            saveRequested={this.saveAboutInfo}
            deleteRequested={this.deleteAboutInfo}
            dismissRequested={() => this.setState({ aboutInfoMode: false })}
          />
        );
      // }
    }
    return (
      <Form>
        <Col>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="name">
                Name{" "}
              </Label>
              <Input
                onChange={this.nameChanged}
                type="text"
                value={this.state.editingNode.LibraryInternationalization.Name}
                name="name"
                id="name"
                width="8px"
              />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="resetPasswordUrl">
                Reset Password Url{" "}
              </Label>
              <Input
                onChange={this.passwordUrlChanged}
                type="text"
                value={this.state.editingNode.LibraryInternationalization.ResetPasswordUrl}
                name="resetPasswordUrl"
                id="resetPasswordUrl"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="registrationUrl">
                Registration Url{" "}
              </Label>
              <Input
                onChange={this.registrationUrlChanged}
                type="text"
                value={this.state.editingNode.LibraryInternationalization.RegistrationUrl}
                name="registrationUrl"
                id="registrationUrl"
              />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="loginCustomPlaceholder">
                Login Custom Placeholder{" "}
              </Label>
              <Input
                onChange={this.loginCustomPlaceholderChanged}
                type="text"
                value={this.state.editingNode.LibraryInternationalization.LoginCustomPlaceholder}
                name="loginCustomPlaceholder"
                id="loginCustomPlaceholder"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="loginFlavourText">
                Login Flavour Text{" "}
              </Label>
              <Input
                onChange={this.loginFlavourTextChanged}
                type="text"
                value={this.state.editingNode.LibraryInternationalization.LoginFlavourText}
                name="loginFlavourText"
                id="loginFlavourText"
              />
            </FormGroup>

            <FormGroup check style={{ flex: "1" }}>
              <Label check for="language">
                Language{" "}
              </Label>
              <Input
                id="language"
                type="select"
                name="language"
                value={this.state.editingNode.LibraryInternationalization.Language}
                placeholder={"Language"}
                onChange={this.languageChanged}
              >
                {Object.keys(Models.LanguageCode)
                  .filter((key) => isNaN(Number(Models.LanguageCode[key as keyof typeof Models.LanguageCode])))
                  .map((it) => (
                    <option value={it} key={it} data-providerval={it}>
                      {+it === Models.LanguageCode.Undefined ? "Preferred Language*" : Models.LanguageCode[it as keyof typeof Models.LanguageCode]}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="privacyPolicyLink">
                Privacy Policy Link{" "}
              </Label>
              <Input
                onChange={this.privacyPolicyLinkChanged}
                type="text"
                value={this.state.editingNode.LibraryInternationalization.PrivacyPolicyLink}
                name="privacyPolicyLink"
                id="privacyPolicyLink"
              />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="licenseAgreementLink">
                License Agreement Link{" "}
              </Label>
              <Input
                onChange={this.licenseAgreementLinkChanged}
                type="text"
                value={this.state.editingNode.LibraryInternationalization.LicenceAgreementLink}
                name="licenseAgreementLink"
                id="licenseAgreementLink"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="accountNotActiveMessage">
                Account Not Active Message{" "}
              </Label>
              <Input
                onChange={this.accountNotActiveMessageChanged}
                type="textarea"
                value={this.state.editingNode.LibraryInternationalization.AccountNotActiveMessage}
                name="accountNotActiveMessage"
                id="accountNotActiveMessage"
              />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="libraryNoTitlesMessage">
                Library No Titles Message{" "}
              </Label>
              <Input
                onChange={this.libraryNoTitlesMessageChanged}
                type="textarea"
                value={this.state.editingNode.LibraryInternationalization.LibraryNoTitlesMessage}
                name="libraryNoTitlesMessage"
                id="libraryNoTitlesMessage"
              />
            </FormGroup>
          </Row>
        </Col>
        <Col>
          <Row className="formButtons">
            {this.props.deleteRequested && (
              <Button onClick={() => this.props.deleteRequested!(this.state.editingNode)} color="danger" outline>
                Delete
              </Button>
            )}
            {this.props.saveRequested && (
              <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                Save
              </Button>
            )}
            <Button onClick={() => this.setState({ aboutInfoMode: true })} color="info">
              About Infos
            </Button>
          </Row>
        </Col>
      </Form>
    );
  }
}

export interface ICreateLibraryInternationalizationFormProps {
  initialNode: Models.ILibraryInternationalizationViewModel;
  // aboutInfo: Models.IAboutInfoViewModel;
  saveRequested?: (node: Models.ILibraryInternationalizationViewModel) => void;
  reloadPermissions: () => void;
}
export interface ICreateLibraryInternationalizationFormState {
  editingNode: Models.ILibraryInternationalizationViewModel;
  // aboutInfo: Models.IAboutInfoViewModel;
  currentStep: number;
  expiresNever: boolean;
  currentDrawerContent: JSX.Element | null;
  selectedInternationalization: number;
  aboutInfoMode: boolean;
}
export class CreateLibraryInternationalizationForm extends React.Component<
  ICreateLibraryInternationalizationFormProps,
  ICreateLibraryInternationalizationFormState
> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ICreateLibraryInternationalizationFormProps) {
    super(props);
    this.state = {
      editingNode: this.props.initialNode,
      // aboutInfo: this.props.aboutInfo,
      currentStep: 1,
      expiresNever: false,
      selectedInternationalization: 0,
      currentDrawerContent: null,
      aboutInfoMode: false,
    };
  }
  nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          Name: e.target.value,
        },
      },
    }));
  };
  passwordUrlChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          ResetPasswordUrl: e.target.value,
        },
      },
    }));
  };
  registrationUrlChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          RegistrationUrl: e.target.value,
        },
      },
    }));
  };
  accountNotActiveMessageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          AccountNotActiveMessage: e.target.value,
        },
      },
    }));
  };
  libraryNoTitlesMessageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          LibraryNoTitlesMessage: e.target.value,
        },
      },
    }));
  };
  licenseAgreementLinkChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          LicenseAgreementLink: e.target.value,
        },
      },
    }));
  };
  privacyPolicyLinkChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          PrivacyPolicyLink: e.target.value,
        },
      },
    }));
  };
  languageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          Language: +e.target.value,
        },
      },
    }));
  };
  loginFlavourTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          LoginFlavourText: e.target.value,
        },
      },
    }));
  };

  loginCustomPlaceholderChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        LibraryInternationalization: {
          ...prevState.editingNode.LibraryInternationalization,
          LoginCustomPlaceholder: e.target.value,
        },
      },
    }));
  };

  handleAboutJsonUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkFileType(e)) {
      if (files && files.length > 0) {
        let reader = new FileReader();
        reader.readAsText(files[0]);

        reader.onload = () => {
          let basedata = reader.result;
          if (basedata === null) {
            return;
          }
          basedata = basedata.toString();
          let baseDataString = basedata;
          this.setState((prevState) => ({
            editingNode: {
              ...prevState.editingNode,
              LibraryInternationalization: {
                ...prevState.editingNode.LibraryInternationalization,
                AboutJson: baseDataString,
              },
            },
          }));
        };
        reader.onerror = (error) => error;
        Messages.Notify.success("File uploaded successfully");
      }
    }
  };
  checkFileType = (event: any) => {
    // getting file object
    let files = event.target.files;
    // define message container
    let err = "";
    // list allow mime type
    const types = ["application/json"];
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every((type) => files[x].type !== type)) {
        // create error message and assign to container
        err = "Invalid file type (expected file type is .json)";
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      Messages.Notify.error(err);
      return false;
    }
    return true;
  };
  handleAboutHtmlUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let files = e.target.files;
    if (this.checkFileType(e)) {
      if (files && files.length > 0) {
        let reader = new FileReader();
        reader.readAsText(files[0]);

        reader.onload = () => {
          let basedata = reader.result;
          if (basedata === null) {
            return;
          }
          basedata = basedata.toString();
          let baseDataString = basedata;
          this.setState((prevState) => ({
            editingNode: {
              ...prevState.editingNode,
              LibraryInternationalization: {
                ...prevState.editingNode.LibraryInternationalization,
                AboutHtml: baseDataString,
              },
            },
          }));
        };
        reader.onerror = (error) => error;
        Messages.Notify.success("File uploaded successfully");
      }
    }
  };

  formValid = () => {
    if (this.state.editingNode.LibraryInternationalization.Name === null) {
      return false;
    }

    return true;
  };

  private saveAboutInfo = async (e: Models.IAboutInfoViewModel) => {
    let response = await this.context.insertOrUpdateAboutInfo({
      AboutInfo: e.AboutInfo,
    });
    if (response.valid()) {
      Messages.Notify.success(`The Internationalization Information was saved successfully!`);
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private deleteAboutInfo = async (e: Models.IAboutInfoViewModel) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to delete this Library Internationalization?`);
    if (result === "true") {
      let response = await this.context.deleteAboutInfo({ AboutInfo: e.AboutInfo });
      if (response.valid()) {
        Messages.Notify.success(`The Internationalization Information item was deleted successfully!`);
        this.setState({ aboutInfoMode: false });
      } else {
        if (response.errors.length > 0) {
          Messages.Notify.error("Deletion failed. Server reported: " + response.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };


  render() {
    if (this.state.aboutInfoMode === true) {
      return (
        <AboutInfoForm
          initialNode={{
            AboutInfo: { Html: "", Title: "", LibraryInternationalizationTableId: this.state.editingNode.LibraryInternationalization.TableId, TableId: 0 },
            Index: -1,
            IsFirst: false,
            IsLast: false,
          }}
          internationalization={this.state.editingNode}
          saveRequested={this.saveAboutInfo}
          deleteRequested={this.deleteAboutInfo}
          dismissRequested={() => this.setState({ aboutInfoMode: false })}
        />
      );
    }
    return (
      <Form>
        <Col>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="name">
                Name *{" "}
              </Label>
              <Input onChange={this.nameChanged} type="text" name="name" id="name" width="8px" />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="resetPasswordUrl">
                Reset Password Url *{" "}
              </Label>
              <Input onChange={this.passwordUrlChanged} type="text" name="resetPasswordUrl" id="resetPasswordUrl" />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="registrationUrl">
                Registration Url *{" "}
              </Label>
              <Input onChange={this.registrationUrlChanged} type="text" name="registrationUrl" id="registrationUrl" />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="loginCustomPlaceholder">
                Login Custom Placeholder *{" "}
              </Label>
              <Input onChange={this.loginCustomPlaceholderChanged} type="text" name="loginCustomPlaceholder" id="loginCustomPlaceholder" />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="loginFlavourText">
                Login Flavour Text
              </Label>
              <Input onChange={this.loginFlavourTextChanged} type="text" name="loginFlavourText" id="loginFlavourText" />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="language">
                Language *{" "}
              </Label>
              <Input
                id="language"
                type="select"
                name="language"
                value={this.state.editingNode.LibraryInternationalization.Language}
                placeholder={"Language"}
                onChange={this.languageChanged}
              >
                {Object.keys(Models.LanguageCode)
                  .filter((key) => isNaN(Number(Models.LanguageCode[key as keyof typeof Models.LanguageCode])))
                  .map((it) => (
                    <option value={it} key={it} data-providerval={it}>
                      {+it === Models.LanguageCode.Undefined ? "Preferred Language*" : Models.LanguageCode[it as keyof typeof Models.LanguageCode]}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="privacyPolicyLink">
                Privacy Policy Link *{" "}
              </Label>
              <Input onChange={this.privacyPolicyLinkChanged} type="text" name="privacyPolicyLink" id="privacyPolicyLink" />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="privacyPolicyLink">
                License Agreement Link *{" "}
              </Label>
              <Input onChange={this.licenseAgreementLinkChanged} type="text" name="licenseAgreementLink" id="licenseAgreementLink" />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="accountNotActiveMessage">
                Account Not Active Message *{" "}
              </Label>
              <Input onChange={this.accountNotActiveMessageChanged} type="textarea" name="accountNotActiveMessage" id="accountNotActiveMessage" />
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="libraryNoTitlesMessage">
                Library No Titles Message *{" "}
              </Label>
              <Input onChange={this.libraryNoTitlesMessageChanged} type="textarea" name="libraryNoTitlesMessage" id="libraryNoTitlesMessage" />
            </FormGroup>
          </Row>
        </Col>
        <Col>
          <FormGroup style={{ flex: "1", display: "flex", justifyContent: "center" }}>
            <Row className="formButtons">
              <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                Create
              </Button>
            </Row>
          </FormGroup>
        </Col>
      </Form>
    );
  }
}
