import { saveAs } from 'file-saver';
import mime from 'mime';
import React from 'react';
import { Button, FormGroup, Input, Row } from 'reactstrap';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import { DataItem, DataRow, DataTable } from '../DataTable';
import { Action, IBatch, INode, IResponse } from '../StandaloneCogniflow';

interface IAnnouncementAttachmentFormProps {
  parentAnnouncement: Models.IAnnouncement;
  currentAttachments: Models.IAnnouncementAttachment[];
  handleNewAttachment: (newAttach: Models.IAnnouncementAttachment) => void;
  handleUpdatedAttachment: (newAttach: Models.IAnnouncementAttachment) => void;
  deleteAttachment: (removeAttach: Models.IAnnouncementAttachment) => void;
}
interface IAnnouncementAttachmentFormState {
  editingAttachment: number;
}

export class AnnouncementAttachmentForm extends React.Component<
  IAnnouncementAttachmentFormProps,
  IAnnouncementAttachmentFormState
> {
  attachmentTable = React.createRef<DataTable>();

  constructor(
    props:
      | IAnnouncementAttachmentFormProps
      | Readonly<IAnnouncementAttachmentFormProps>
  ) {
    super(props);
    this.state = { editingAttachment: -1 };
  }

  handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0] !== undefined) {
      this.setNewAttachment(e.target.files![0]!);
    }
  };

  componentDidUpdate(prevProps: IAnnouncementAttachmentFormProps) {
    if (
      prevProps.currentAttachments.length !==
      this.props.currentAttachments.length
    ) {
      this.attachmentTable.current!.reload();
    }
  }

  setNewAttachment = (file: File) => {
    let reader = new FileReader();
    reader.onload = () => {
      let dataReplace = /data: ?.*; ?base64. ?/g;
      let fileData = (reader.result! as string).replace(dataReplace, "");
      let len = fileData.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = fileData.charCodeAt(i);
      }
      let extension = "";
      if (file) {
        extension = "." + file.name.split(".").pop() ?? "";
      }

      let index = this.props.currentAttachments.findIndex(
        (x) => x.Name === file.name
      );
      let existing = index >= 0;
      let attachment: Models.IAnnouncementAttachment = existing
        ? this.props.currentAttachments[index]
        : ({} as Models.IAnnouncementAttachment);

      attachment.Name = file.name.trimEnd().replace(/\.[^/.]+$/, "");
      attachment.AttachmentData = window.btoa(
        new Uint8Array(bytes).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      attachment.AttachmentExtension = extension;
      if (!existing) attachment.CreationDate = new Date(Date.now());
      attachment.LastModificationDate = new Date(Date.now());
      attachment.AnnouncementId = this.props.parentAnnouncement.TableId;
      if (!existing) this.props.handleNewAttachment(attachment);
      else this.props.handleUpdatedAttachment(attachment);
    };
    reader.readAsDataURL(file);
  };
  private attachmentFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve) => {
      resolve({ Batches: [] });
    });

  private initializeAttachments = (
    anchor?: number,
    query?: string
  ): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      let result = this.props.currentAttachments;
      if (result === null) {
        reject();
        return;
      }
      let request: IBatch = {
        Action: Action.insert,
        AnchorMainId: 0,
        Nodes: [],
        BatchSize: Models.genericDataSettings.batchSize,
        TargetMainId: 0,
        Query: query
      };
      request.Nodes = result;
      request.BatchSize = 10000;
      resolve({
        nodes: Convert.indexify(request).Nodes,
        targetSpine: 0
      });
    });
  selectAttachment = (n: INode) => {
    let node = n as Models.IAnnouncementAttachment;
    this.setState({ editingAttachment: node.Index }, () => {
      this.attachmentTable.current!.reRender();
    });
  };
  private generateAttachment = (n: INode) => {
    let node = n as Models.IAnnouncementAttachment;
    let dataItems = [];
    let attrs: any = {};
    attrs[
      Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute
    ] = node.TableId;
    attrs[
      Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute
    ] = node.Index;

    dataItems.push(
      <DataItem flexVal={2} className="" key={1} value={node.Name} />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        className="rightBorder leftBorder centerText"
        key={2}
        value={node.AttachmentExtension}
      />
    );
    dataItems.push(
      <DataItem
        flexVal={1}
        className="centerText"
        key={3}
        value={node.AttachmentData.length.toString() + " bytes"}
      />
    );
    return (
      <DataRow
        className={
          this.state.editingAttachment === node.Index ? " selected" : ""
        }
        node={node}
        key={node.Index}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.selectAttachment}
      />
    );
  };
  attachmentDeleteRequest = () => {
    let attachment =
      this.props.currentAttachments[this.state.editingAttachment];
    this.setState({ editingAttachment: -1 }, () =>
      this.props.deleteAttachment(attachment)
    );
  };
  attachmentDownloadRequest = () => {
    let attachment =
      this.props.currentAttachments[this.state.editingAttachment];
    let binaryString = window.atob(attachment.AttachmentData.toString());
    try {
      let base64regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      while (base64regex.test(binaryString)) {
        binaryString = window.atob(binaryString);
      }
    } catch (ex1) {
      try {
        while (true) {
          binaryString = window.atob(binaryString);
        }
      } catch (ex2) {}
    }
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    let mimeType = mime.getType(attachment.AttachmentExtension);
    let mimeTypeString = mimeType ? mimeType : "";

    let blob = new Blob([bytes], { type: mimeTypeString });
    saveAs(blob, attachment.Name + attachment.AttachmentExtension);
  };

  downloadFile = (fileName: string, data: string, mimeType: string) => {
    let element = document.createElement("a");
    element.setAttribute("href", "data:" + mimeType + ";charset=utf-8," + data);
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  render() {
    let setting = JSON.parse(JSON.stringify(Models.genericDataSettings));
    return (
      <div>
        <div className="attachmentsTable">
          <DataTable
            tableClassName="full-height"
            headers={["Filename", "Extension", "Size"]}
            headerFlexes={[2, 1, 1]}
            flowProvider={this.attachmentFlowProvider}
            initializeFlowProvider={this.initializeAttachments}
            objectBuilder={this.generateAttachment}
            ref={this.attachmentTable}
            settingsOverride={setting}
            canDelete
            selectedRowDelete={this.attachmentDeleteRequest}
          />
        </div>
        <div className="attachmentsControl">
          <Row>
            <FormGroup style={{ flex: "1", display: "flex" }}>
              <Input
                style={{ width: "initial" }}
                accept="*"
                type="file"
                name="tipThumbnail"
                id="tipThumbnail"
                onChange={this.handleAttachmentChange}
              />
            </FormGroup>
            <FormGroup>
              <Button
                disabled={this.state.editingAttachment < 0}
                onClick={this.attachmentDownloadRequest}
                color="info"
                outline
              >
                Download selected attachment
              </Button>
            </FormGroup>
          </Row>
        </div>
      </div>
    );
  }
}
