import React, { Fragment } from "react";
import { Convert, ResourcePlatform } from "src/utilities/Helpers";

import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { ProLibroMarkdown } from "./proLibroMarkdown";

const resizeIframe = (obj: React.SyntheticEvent, prevHeight: number) => {
  setTimeout(() => {
    if ((obj.target as HTMLIFrameElement).contentWindow !== null && (obj.target as HTMLIFrameElement).contentWindow!.document.documentElement !== null) {
      let heightTarget = (obj.target as HTMLIFrameElement).contentWindow!.document.documentElement.children[1].scrollHeight * 1.3;
      if (prevHeight !== heightTarget) {
        (obj.target as HTMLIFrameElement).style.height = heightTarget + "px";
        (obj.target as HTMLIFrameElement).style.minHeight = "40pt";
        if (prevHeight === 0) {
          (obj.target as HTMLIFrameElement).closest(".innerCommunity")!.scrollTop = 0;
        }
        prevHeight = heightTarget;
      }
    }
    resizeIframe(obj, prevHeight);
  }, 200);
};

const preHandleChange = (handleChange: (path: string, newObj: any) => void, path: string, content: string, fieldType: string) => {
  let newObj = { fieldType: fieldType, content: content };
  handleChange(path, newObj);
};

const RichContentControl = (props: ControlProps) => {
  // const [text, handleChange] = React.useState<string>(data.data === undefined ? "" : data.data.content);
  // const [fieldType, handleChange] = React.useState<string>(data.data === undefined ? "md" : data.data.fieldType);
  let text = props.data === undefined ? "" : (props.data.content as string);
  let fieldType = props.data === undefined ? "md" : (props.data.fieldType as string);
  // const [markdownSelectedTab, setMarkdownSelectedTab] = React.useState<"write" | "preview" | undefined>();
  // The form element is allowing editing to occur.
  if (props.enabled === true) {
    let radios = (
      <RadioGroup row value={fieldType} onChange={(e) => preHandleChange(props.handleChange, props.path, text, e.target.value)} name="radio-buttons-group">
        <FormControlLabel value="md" control={<Radio />} label="Markdown" />
      </RadioGroup>
    );
    if (fieldType === "md") {
      let style: React.CSSProperties = { maxWidth: "initial" };
      if (Convert.isEmptyOrSpaces(text) && props.required === true) {
        style = { maxWidth: "initial", border: "2px solid red" };
      }
      return (
        <div style={style}>
          {radios}
          {<ProLibroMarkdown value={text} onChange={(e) => preHandleChange(props.handleChange, props.path, e ? e : "", fieldType)} />}
        </div>
      );
    } else {
      return (
        <div>
          {radios}
          <textarea
            name="textarea"
            id="html"
            required
            placeholder="Enter basic HTML here"
            className="htmlEditor bg-white rounded shadow w-full"
            value={text}
            onChange={(e) => preHandleChange(props.handleChange, props.path, e.target.value, fieldType)}
          />
        </div>
      );
    }
  }
  // The form element is read only. "Display mode".
  else {
    if (fieldType === "md") {
      return (
        <div>
          <dl>
            <Fragment>{ResourcePlatform.toMarkdown(props.data?.content as string, "")}</Fragment>
          </dl>
        </div>
      );
    } else {
      return (
        <div className="">
          <iframe
            title="formFrame"
            className="formFrame"
            srcDoc={props.data.content.toString() + "<style type='text/css'>img{max-width:100% !important; height:auto;}</style>"}
            frameBorder="0"
            scrolling="no"
            onLoad={(e) => resizeIframe(e, 0)}
          />
        </div>
      );
    }
  }
};
export default withJsonFormsControlProps(RichContentControl);
