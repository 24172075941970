import React from 'react';
import { Languages } from 'src/localization/Locale';
import { AppSession } from 'src/models/AppSession';
import { UserFormSubmissionState, UserFormSubmissionType } from 'src/models/dto/UserFormRequest';
import { Drawer, DrawerContainer } from 'src/ui/foundation/Controls';
import { UserFormStateFilter } from 'src/ui/foundation/Controls/UserFormStateFilter';
import { DataItem, DataRow, DataTable } from 'src/ui/foundation/DataTable';
import { DashboardView } from 'src/ui/foundation/Layout';
import { Action, INode, IRequest, IResponse } from 'src/ui/foundation/StandaloneCogniflow';
import { AppContext } from 'src/ui/state/Contextes';
import { Convert } from 'src/utilities/Helpers';

import * as Models from '../../../models/dto/DashboardModels';
import * as Messages from '../../foundation/Messages';
import { PanelView } from '../PanelView';
import { TipForm } from './TipForm';

export interface ITipViewProps {
  IsLoggedIn: boolean;
}
export interface ITipViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  tips: [];
  panelDisabled: boolean;
  editingTip: Models.IUserFormViewModel | null;
  panelList: Models.ILoginLibrary[];
  selectedPublisher: number;
  selectedTitle: Models.ITitle | null;
  loading: boolean;
  name: string;
  logo: string;
  userFormFilter: UserFormSubmissionState;
}
export class TipView extends React.Component<ITipViewProps, ITipViewState> {
  context: AppSession;
  static contextType = AppContext;
  tipsTable = React.createRef<DataTable>();
  titlesTable = React.createRef<DataTable>();
  panelRef = React.createRef<PanelView>();

  constructor(props: ITipViewProps) {
    super(props);
    this.state = {
      drawerShow: false,
      currentDrawerContent: null,
      panelDisabled: false,
      tips: [],
      editingTip: null,
      panelList: [],
      selectedPublisher: 0,
      selectedTitle: null,
      loading: true,
      name: "",
      logo: "",
      userFormFilter: UserFormSubmissionState.New,
    };
  }

  componentDidMount() {
    this.context.viewedViews.get(DashboardView.Tips)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.Tips)!.loading.off(this.loginInit);
  }

  private reloadTitleAndDismiss = () => {
    this.titlesTable.current!.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };

  publisherChanged = (newPub: Models.ILoginLibrary) => {
    this.setState({ loading: true }, () => {
      this.setState(
        {
          selectedPublisher: newPub.PublisherId,
          name: newPub.DisplayName,
          logo: newPub.LibraryIcon,
          loading: false,
          selectedTitle: null,
        },
        () => {
          this.reloadTitleAndDismiss();
          this.tipsTable.current!.reload();
        }
      );
    });
  };

  loginInit = () => {
    let allowedPublisher = this.context.getManageableUserFormsPublishers(UserFormSubmissionType.Tip);
    this.context.viewedViews.get(DashboardView.Tips)!.progressLoading();
    if (allowedPublisher.length === 0) {
      return;
    } else {
      this.setState(
        {
          panelList: allowedPublisher,
          selectedPublisher: allowedPublisher[0].PublisherId,
        },
        () => {
          this.publisherChanged(allowedPublisher[0]);
        }
      );
    }
  };

  private tipsFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowUserForms({
        FlowRequest: request.Batches[0],
        TitleId: this.state.selectedTitle === null ? 0 : this.state.selectedTitle.TableId,
        FormType: UserFormSubmissionType.Tip,
        StateFilter: this.state.userFormFilter,
        PublisherId: this.state.selectedPublisher,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });
  private initializeTips = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowUserForms({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        TitleId: this.state.selectedTitle === null ? 0 : this.state.selectedTitle.TableId,
        FormType: UserFormSubmissionType.Tip,
        PublisherId: this.state.selectedPublisher,
        StateFilter: this.state.userFormFilter,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private saveTip = async (e: Models.IUserFormSubmission, titles: Models.ITitle[], att: Models.IUserFormSubmissionAttachment[]) => {
    let response = await this.context.insertOrUpdateUserForm({
      UserForm: e,
      Titles: titles.map((x) => x.TableId),
      PublisherId: this.state.selectedPublisher,
      FormType: UserFormSubmissionType.Tip,
      Attachments: att,
    });
    if (response.valid()) {
      Messages.Notify.success(`The user form was saved successfully!`);
      this.tipsTable.current!.reload();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };

  private tipRowEditRequest = (e: INode) => {
    this.setState({
      drawerShow: true,
      currentDrawerContent: <TipForm initialNode={e as Models.IUserFormViewModel} saveRequested={this.saveTip} deleteRequested={this.tipDeleteRequest} />,
    });
  };

  private generateTips = (n: INode): JSX.Element => {
    let node = n as Models.IUserFormViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    try {
      let submission = node.Submission === "" ? {} : JSON.parse(node.Submission);
      let title = submission.title;
      dataItems.push(<DataItem flexVal={1} key={1} value={title} />);
    } catch (e) {
      dataItems.push(<DataItem flexVal={1} key={1} value={"Could not parse submission."} />);
    }
    dataItems.push(<DataItem flexVal={1} key={2} value={Convert.dateToFormattedString(node.CreationDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={1} key={3} value={Convert.dateToFormattedString(node.LastModificationDate, Languages.English)} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.tipRowEditRequest} />;
  };

  private titleEditRequest = (e: INode) => {
    this.setState(
      {
        selectedTitle: e as Models.ITitle,
      },
      () => {
        this.titlesTable.current!.reRender();
        this.tipsTable.current!.reload();
      }
    );
  };

  private titleFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: request.Batches[0],
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private initializeTitle = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private generateTitle = (n: INode): JSX.Element => {
    let node = n as Models.ITitle;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={2} key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="leftBorder" value={Models.TitleStatus[node.TitleStatus]} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="leftBorder" value={Convert.dateToFormattedString(node.PublicationDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={2} key={4} className="leftBorder" value={Convert.dateToFormattedString(node.ExpirationDate, Languages.English)} />);

    return (
      <DataRow
        className={this.state.selectedTitle !== null && this.state.selectedTitle.TableId === node.TableId ? " selected" : ""}
        node={node}
        key={node.TableId}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.titleEditRequest}
      />
    );
  };

  private titleQueryExecute = (query: string) => {
    this.titlesTable.current!.reload(query);
  };

  tipDeleteRequest = async (e: Models.IUserFormSubmission) => {
    let result = await Messages.Dialog.confirm(`Are you sure you wish to reject this user tip? It will no longer appear on the Reader.`, `Reject User Tip`);
    if (result === "true") {
      let resp = await this.context.deleteUserForm({ Id: e.TableId, FormType: 0 });
      if (resp.valid()) {
        Messages.Notify.success("Submission rejected successfully!");
        this.tipsTable.current!.reload();
        this.setState({ currentDrawerContent: null, drawerShow: false });
      } else {
        if (resp.errors.length > 0) {
          Messages.Notify.error("Fetch failed. Server reported: " + resp.errors[0].Message);
        } else {
          Messages.Notify.error("An error occurred while executing the communication");
        }
      }
    }
  };

  tipQueryExecute = (query: string) => {
    this.tipsTable.current!.reload(query);
  };

  render() {
    if (!this.props.IsLoggedIn || !this.context.viewedViews.get(DashboardView.Tips)!.isLoaded()) {
      return "";
    }

    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.batchSize = 25;
    settings.maxHeight = "300px";
    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          ref={this.panelRef}
          disabled={this.state.loading}
          publisherList={this.state.panelList}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.panelList.find((x) => x.PublisherId === this.state.selectedPublisher)!}
        />
        <div className="tipView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="tipsViewInner">
              <div className="section">
                <h1>Welcome to the Tips View</h1>
                <p>Welcome to the tips view. This view is used to manage the tips of the selected titles.</p>
              </div>
              {this.context.canManageUserForms(UserFormSubmissionType.Tip, this.state.selectedPublisher) && (
                <div className="section">
                  <h2>Titles</h2>
                  <p> This list the titles of the selected publisher. </p>
                  <DataTable
                    headers={["Name", "Status", "Publication Date", "Expiration Date"]}
                    headerFlexes={[2, 1, 2, 2]}
                    flowProvider={this.titleFlowProvider}
                    initializeFlowProvider={this.initializeTitle}
                    objectBuilder={this.generateTitle}
                    ref={this.titlesTable}
                    settingsOverride={settings}
                    searchQueryComitted={this.titleQueryExecute}
                  />
                </div>
              )}
              {this.context.canManageUserForms(UserFormSubmissionType.Tip, this.state.selectedPublisher) && (
                <div className="section">
                  <h2>Tips</h2>
                  <p>Manage Tips. The list of all the tips of the title you select. </p>
                  <h3>Currently showing tips for: {this.state.selectedTitle === null ? "All titles" : this.state.selectedTitle.Name}</h3>
                  <UserFormStateFilter
                    currentSelection={this.state.userFormFilter}
                    selectedFilterChanged={(e) => this.setState({ userFormFilter: e }, () => this.tipsTable.current!.reload())}
                  />
                  <DataTable
                    headers={["Title", "Creation Date", "Last Modification"]}
                    headerFlexes={[1, 1, 1]}
                    searchQueryComitted={this.tipQueryExecute}
                    flowProvider={this.tipsFlowProvider}
                    initializeFlowProvider={this.initializeTips}
                    objectBuilder={this.generateTips}
                    ref={this.tipsTable}
                    settingsOverride={settings}
                  />
                </div>
              )}
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
