import React from "react";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { Icon, Loading } from "src/ui/foundation/Controls";
import { ProLibroMarkdown } from "src/ui/foundation/Controls/proLibroMarkdown";
import { UserInfoBlock } from "src/ui/foundation/Controls/UserInfoBlock";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import { Image } from "../../foundation/Assets";

export interface IGlobalNoteFormProps {
  initialNode: Models.GlobalNoteViewModel;
  deleteRequested?: (node: Models.GlobalNoteViewModel) => void;
  saveRequested?: (node: Models.GlobalNoteViewModel) => void;
  reloadGlobalNotes?: () => void;
}

export interface IGlobalNoteFormState {
  editingNode: Models.GlobalNoteViewModel;
  loading: boolean;
}

export class GlobalNoteForm extends React.Component<IGlobalNoteFormProps, IGlobalNoteFormState> {
  context: AppSession;
  static contextType = AppContext;

  constructor(props: IGlobalNoteFormProps | Readonly<IGlobalNoteFormProps>) {
    super(props);
    this.state = {
      loading: false,
      editingNode: {
        TableId: this.props.initialNode.TableId,
        CreationDate: this.props.initialNode.CreationDate,
        TableGuid: this.props.initialNode.TableGuid,
        UserId: this.props.initialNode.UserId,
        Breadcrumb: this.props.initialNode.Breadcrumb,
        Creator: this.props.initialNode.Creator,
        FriendlyNoteState: this.props.initialNode.FriendlyNoteState,
        LastModified: this.props.initialNode.LastModified,
        IsVisible: this.props.initialNode.IsVisible,
        Name: this.props.initialNode.Name,
        Note: this.props.initialNode.Note,
        Type: this.props.initialNode.Type,
        VersionId: this.props.initialNode.VersionId,
        Index: -1,
        IsFirst: false,
        IsLast: false,
        State: this.props.initialNode.State,
        ApprovalDate: this.props.initialNode.ApprovalDate,
        ApproverUserId: this.props.initialNode.ApproverUserId,
        LastModificationUserId: this.props.initialNode.LastModificationUserId,
        Approver: this.props.initialNode.Approver,
        Modifier: this.props.initialNode.Modifier,
        VersionGuid: this.props.initialNode.VersionGuid,
        VersionState: this.props.initialNode.VersionState,
      },
    };
  }
  async componentDidMount() {}

  handleChange = (value?: string) => {
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Note: value! },
    }));
  };
  handleNoteNameChanged = (e: any) => {
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Name: e.target.value },
    }));
  };
  handleNoteStateChanged = (e: Models.GlobalNoteState) => {
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, FriendlyNoteState: e, State: e },
    }));
  };

  globalNoteStateToString = (state: Models.GlobalNoteState): string => {
    switch (state) {
      case Models.GlobalNoteState.InReview:
        return "In Review";
      case Models.GlobalNoteState.Published:
        return "Published";
      case Models.GlobalNoteState.Rejected:
        return "Rejected";
    }
  };

  render() {
    let paddedId = this.props.initialNode.TableId.toString().padStart(6, "0");
    return (
      <Loading className="full-width full-height" isLoading={this.state.loading} theme="opaque" status="Loading GlobalNote...">
        <div className="form-container full-width full-height">
          <div className="GlobalNote-tabs">
            <div className="form-container">
              <div className="globalNoteInfo">
                <h3>
                  <span className="globalNoteSubHeader">{this.props.initialNode.Name} Details</span>
                </h3>
                <h5>
                  <span className="globalNoteSubHeader">Global Note ID: {paddedId}</span>
                </h5>
                <h5>
                  <span className="globalNoteSubHeader">
                    For version Ref: {this.props.initialNode.VersionGuid} ({this.props.initialNode.VersionState})
                  </span>
                </h5>
                <div className="currentState">
                  <h4 className="detailHeader">Current status: {this.globalNoteStateToString(this.props.initialNode.FriendlyNoteState)}</h4>
                </div>
                <div className="globalNoteDetails">
                  <div className="submissionInfoHeader">
                    <span>
                      <span style={{ fontWeight: 800 }}>Created on:</span>{" "}
                      {Convert.dateToFormattedString(this.props.initialNode.CreationDate, Languages.English)} (UTC)
                    </span>
                    <span>
                      <span style={{ fontWeight: 800 }}>Last modified on:</span>{" "}
                      {Convert.dateToFormattedString(this.props.initialNode.LastModified, Languages.English)} (UTC)
                    </span>
                    {this.props.initialNode.ApprovalDate && this.props.initialNode.ApprovalDate !== null && (
                      <span>
                        <span style={{ fontWeight: 800 }}>Approved on:</span>{" "}
                        {Convert.dateToFormattedString(this.props.initialNode.ApprovalDate, Languages.English)} (UTC)
                      </span>
                    )}
                  </div>
                  <span>
                    <span className="detailHeader">Location in content </span>
                    <span className="locationValue">{this.state.editingNode.Breadcrumb}</span>
                  </span>
                </div>
                <h5>Created by</h5>
                <UserInfoBlock User={this.props.initialNode.Creator} />
                {this.props.initialNode.Modifier && <h5>Modified by</h5>}
                <UserInfoBlock User={this.props.initialNode.Modifier} />
                {this.props.initialNode.Approver && <h5>Approved by</h5>}
                <UserInfoBlock User={this.props.initialNode.Approver} />

                <div style={{ maxWidth: "initial" }}>
                  <span className="noteHeader detailHeader">Name:</span>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupText>
                        <Icon src={<Image.card />} />
                      </InputGroupText>
                      <Input
                        type="text"
                        name="firstname"
                        value={this.state.editingNode.Name}
                        placeholder={"Global Note"}
                        onChange={this.handleNoteNameChanged}
                      />
                    </InputGroup>
                  </FormGroup>{" "}
                </div>
                <div style={{ maxWidth: "initial" }}>
                  <span className="noteHeader detailHeader">Note:</span>
                  <ProLibroMarkdown value={this.state.editingNode.Note} onChange={this.handleChange} />
                </div>
                {this.state.editingNode.FriendlyNoteState !== this.props.initialNode.FriendlyNoteState && (
                  <div className="currentState">
                    <h4 className="detailHeader">New state: {this.globalNoteStateToString(this.state.editingNode.FriendlyNoteState)}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
          <FormGroup>
            <Col>
              <Row className="buttonRow">
                <Button
                  style={{ flex: "1", marginRight: "10px" }}
                  onClick={() => this.handleNoteStateChanged(Models.GlobalNoteState.Published)}
                  outline
                  color="info"
                >
                  Publish Global Note
                </Button>
                <Button
                  style={{ flex: "1", marginRight: "10px" }}
                  onClick={() => this.handleNoteStateChanged(Models.GlobalNoteState.Rejected)}
                  outline
                  color="info"
                >
                  Reject Global Note
                </Button>
                <Button style={{ flex: "1" }} onClick={() => this.handleNoteStateChanged(Models.GlobalNoteState.InReview)} outline color="info">
                  Review Global Note
                </Button>
              </Row>
              <Row className="buttonRow">
                <Button style={{ flex: "1", marginRight: "10px" }} onClick={() => this.props.saveRequested!(this.state.editingNode)} outline color="info">
                  Save Note Changes
                </Button>
              </Row>
            </Col>
          </FormGroup>
        </div>
      </Loading>
    );
  }
}
