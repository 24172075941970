import React from "react";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { Drawer, DrawerContainer } from "src/ui/foundation/Controls";
import { DataItem, DataRow, DataTable } from "src/ui/foundation/DataTable";
import { DashboardView } from "src/ui/foundation/Layout";
import { Action, INode, IRequest, IResponse } from "src/ui/foundation/StandaloneCogniflow";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import * as Messages from "../../foundation/Messages";
import { PanelView } from "../PanelView";
import { GlobalNoteForm } from "./GlobalNoteForm";

export interface IGlobalNoteViewProps {
  IsLoggedIn: boolean;
}
export interface IGlobalNoteViewState {
  drawerShow: boolean;
  currentDrawerContent: JSX.Element | null;
  panelDisabled: boolean;
  panelList: Models.ILoginLibrary[];
  selectedPublisher: number;
  selectedTitle: Models.ITitle | null;
  loading: boolean;
  name: string;
  logo: string;
  currentTitles: Models.ITitle[];
}
export class GlobalNoteView extends React.Component<IGlobalNoteViewProps, IGlobalNoteViewState> {
  context: AppSession;
  static contextType = AppContext;
  panelRef = React.createRef<PanelView>();
  titlesTable = React.createRef<DataTable>();
  globalNotesTable = React.createRef<DataTable>();

  constructor(props: IGlobalNoteViewProps) {
    super(props);
    this.state = {
      drawerShow: false,
      currentDrawerContent: null,
      panelDisabled: false,
      selectedPublisher: 0,
      selectedTitle: null,
      loading: true,
      name: "",
      logo: "",
      panelList: [],
      currentTitles: [],
    };
  }
  componentDidMount() {
    this.context.viewedViews.get(DashboardView.GlobalNotes)!.loading.on(this.loginInit);
  }
  componentWillUnmount() {
    this.context.viewedViews.get(DashboardView.GlobalNotes)!.loading.off(this.loginInit);
  }

  private reloadTitleAndDismiss = () => {
    this.titlesTable.current?.reload();
    this.globalNotesTable.current?.reload();
    this.setState({ currentDrawerContent: null, drawerShow: false });
  };

  publisherChanged = (newPub: Models.ILoginLibrary) => {
    this.setState({ loading: true }, () => {
      this.setState(
        {
          selectedPublisher: newPub.PublisherId,
          name: newPub.DisplayName,
          logo: newPub.LibraryIcon,
          loading: false,
          selectedTitle: null,
        },
        () => {
          this.reloadTitleAndDismiss();
          this.globalNotesTable.current?.reload();
        }
      );
    });
  };

  loginInit = () => {
    let allowedPublisher = this.context.getManageablePublishersGlobalNotes();
    this.context.viewedViews.get(DashboardView.GlobalNotes)!.progressLoading();
    if (allowedPublisher.length === 0) {
      return;
    } else {
      this.setState(
        {
          panelList: allowedPublisher,
          selectedPublisher: allowedPublisher[0].PublisherId,
        },
        () => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          this.publisherChanged(allowedPublisher[0]);
        }
      );
    }
  };

  titlesChanged = (titles: Models.ITitle[]) => {
    this.setState(() => ({
      currentTitles: titles,
    }));
  };

  private titleFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: request.Batches[0],
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private initializeTitle = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.state.selectedPublisher,
        ExcludeTitles: [],
        HideInactiveTitles: false,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private generateTitle = (n: INode): JSX.Element => {
    let node = n as Models.ITitle;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];
    dataItems.push(<DataItem flexVal={2} key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} key={2} className="leftBorder" value={Models.TitleStatus[node.TitleStatus]} />);
    dataItems.push(<DataItem flexVal={2} key={3} className="leftBorder" value={Convert.dateToFormattedString(node.PublicationDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={2} key={4} className="leftBorder" value={Convert.dateToFormattedString(node.ExpirationDate, Languages.English)} />);

    return (
      <DataRow
        className={this.state.selectedTitle !== null && this.state.selectedTitle.TableId === node.TableId ? " selected" : ""}
        node={node}
        key={node.TableId}
        attributes={attrs}
        dataItems={dataItems}
        rowEditRequested={this.titleEditRequest}
      />
    );
  };

  private titleEditRequest = (e: INode) => {
    this.setState(
      {
        selectedTitle: e as Models.ITitle,
      },
      () => {
        this.titlesTable.current!.reRender();
        this.globalNotesTable.current?.reload();
      }
    );
  };
  private titleQueryExecute = (query: string) => {
    this.titlesTable.current!.reload(query);
  };

  private globalNotesFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowGlobalNotes({
        FlowRequest: request.Batches[0],
        TitleId: this.state.selectedTitle === null ? 0 : this.state.selectedTitle.TableId,
        PublisherId: this.state.selectedPublisher,
      });
      if (result.valid()) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private initializeGlobalNotes = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      let result = await this.context.flowGlobalNotes({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        TitleId: this.state.selectedTitle === null ? 0 : this.state.selectedTitle.TableId,
        PublisherId: this.state.selectedPublisher,
      });
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  globalNoteStateToString = (state: Models.GlobalNoteState): string => {
    switch (state) {
      case Models.GlobalNoteState.InReview:
        return "In Review";
      case Models.GlobalNoteState.Published:
        return "Published";
      case Models.GlobalNoteState.Rejected:
        return "Rejected";
    }
  };
  private generateGlobalNote = (n: INode): JSX.Element => {
    let node = n as Models.GlobalNoteViewModel;
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;
    let dataItems = [];

    let paddedId = node.TableId.toString().padStart(6, "0");
    dataItems.push(<DataItem flexVal={1} key={1} value={paddedId} />);
    dataItems.push(<DataItem flexVal={1} key={2} value={this.globalNoteStateToString(node.State)} />);
    dataItems.push(<DataItem flexVal={2} key={3} value={node.Creator.Email + " (" + node.UserId.toString() + ")"} />);
    dataItems.push(<DataItem flexVal={1} key={4} value={Convert.dateToFormattedString(node.CreationDate, Languages.English)} />);
    dataItems.push(<DataItem flexVal={1} key={5} value={Convert.dateToFormattedString(node.LastModified, Languages.English)} />);
    dataItems.push(<DataItem flexVal={1} key={6} value={node.VersionState} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.globalNoteRowEditRequest} />;
  };

  saveGlobalNote = async (item: Models.GlobalNoteViewModel) => {
    let response = await this.context.putGlobalNote({ GlobalNote: item, PublisherId: this.state.selectedPublisher });
    if (response.valid()) {
      Messages.Notify.success(`The Global Note item was saved successfully!`);
      this.reloadTitleAndDismiss();
    } else {
      if (response.errors.length > 0) {
        Messages.Notify.error("Save failed. Server reported: " + response.errors[0].Message);
      } else {
        Messages.Notify.error("An error occurred while executing the communication");
      }
    }
  };
  deleteGlobalNote = () => {};
  private globalNoteRowEditRequest = (e: INode) => {
    e.PublisherId = this.state.selectedPublisher;

    this.setState({
      drawerShow: true,
      currentDrawerContent: (
        <GlobalNoteForm initialNode={e as Models.GlobalNoteViewModel} saveRequested={this.saveGlobalNote} deleteRequested={this.deleteGlobalNote} />
      ),
    });
  };

  render() {
    if (!this.props.IsLoggedIn || !this.context.viewedViews.get(DashboardView.GlobalNotes)!.isLoaded()) {
      return "";
    }

    let settings = JSON.parse(JSON.stringify(Models.genericDataSettings));
    settings.batchSize = 25;
    settings.maxHeight = "300px";
    return (
      <div className="mainView" style={{ display: "flex" }}>
        <PanelView
          ref={this.panelRef}
          disabled={this.state.loading}
          publisherList={this.state.panelList}
          publisherChanged={this.publisherChanged}
          showAdd={false}
          selectedPublisher={this.state.panelList.find((x) => x.PublisherId === this.state.selectedPublisher)!}
        />
        <div className="tipView full-height full-width">
          <DrawerContainer direction="top" className="flex-fill d-flex flex-column full-height">
            <Drawer
              onBackdropClicked={() => {
                this.setState({ drawerShow: false, currentDrawerContent: null });
              }}
              isOpen={this.state.drawerShow}
              backdrop={true}
              className="details-view"
            >
              {this.state.currentDrawerContent}
            </Drawer>
            <div className="tipsViewInner">
              <div className="section">
                <h1>Welcome to the Global Notes view</h1>
                <p>
                  Welcome to the Global Notes view. This view is used to manage and publish Global Notes that were created in the proLibro Reader and viewer
                  software.
                </p>
              </div>
              <div className="section">
                <h2>Pick a Title Below to see the Global Notes associated with it.</h2>
                <div className="titles">
                  <DataTable
                    headers={["Name", "Status", "Publication Date", "Expiration Date"]}
                    headerFlexes={[2, 1, 2, 2]}
                    flowProvider={this.titleFlowProvider}
                    initializeFlowProvider={this.initializeTitle}
                    objectBuilder={this.generateTitle}
                    ref={this.titlesTable}
                    settingsOverride={settings}
                    searchQueryComitted={this.titleQueryExecute}
                  />
                </div>
              </div>
              <div className="section">
                <h2>
                  Below are the Global notes for{" "}
                  {this.state.selectedTitle !== null ? "title: " + '"' + this.state.selectedTitle?.Name + '"' : "all titles in review"}.
                </h2>
                <div className="globalNotes">
                  <DataTable
                    headers={["Global Note ID", "State", "Creator", "Creation Date", "Last Modification", "Version State"]}
                    headerFlexes={[1, 1, 2, 1, 1, 1]}
                    flowProvider={this.globalNotesFlowProvider}
                    initializeFlowProvider={this.initializeGlobalNotes}
                    objectBuilder={this.generateGlobalNote}
                    ref={this.globalNotesTable}
                    settingsOverride={settings}
                  />
                </div>
              </div>
              <div className="bottomSpacer" />
            </div>
          </DrawerContainer>
        </div>
      </div>
    );
  }
}
