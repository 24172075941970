import * as React from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Languages } from "src/localization/Locale";
import { AppSession } from "src/models/AppSession";
import { PermissionInviteForm } from "src/ui/foundation/Controls/PermissionInviteForm";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import { CuratorPermissionForm } from "./CuratorPermissionForm";
import { PubManagerPermissionForm } from "./PubManagerPermissionForm";

export interface IPermissionFormProps {
  initialNode: Models.IPermissionViewModel;
  deleteRequested?: (node: Models.IPermissionViewModel) => void;
  saveRequested?: (node: Models.IPermissionViewModel) => void;
  reloadPermissions: () => void;
  goBackBtnClicked?: () => void;
  formMode?: FormMode;
}
export enum FormMode {
  Curator,
  CreatePubManager,
  SystemAdmin,
}
export interface IPermissionFormState {
  editingNode: Models.IPermissionViewModel;
}
export class PermissionForm extends React.Component<IPermissionFormProps, IPermissionFormState> {
  constructor(props: IPermissionFormProps) {
    super(props);
  }
  componentDidMount() {}

  render() {
    let innerform = this.props.deleteRequested ? (
      <EditPermissionForm initialNode={this.props.initialNode} deleteRequested={this.props.deleteRequested} saveRequested={this.props.saveRequested} />
    ) : (
      <CreatePermissionForm initialNode={this.props.initialNode} saveRequested={this.props.saveRequested} reloadPermissions={this.props.reloadPermissions} />
    );
    if (this.props.formMode === FormMode.Curator) {
      innerform = (
        <CuratorPermissionForm
          goBackBtnClicked={this.props.goBackBtnClicked}
          initialNode={this.props.initialNode}
          saveRequested={this.props.saveRequested}
          reloadPermissions={this.props.reloadPermissions}
        />
      );
    }
    if (this.props.formMode === FormMode.CreatePubManager) {
      innerform = (
        <PubManagerPermissionForm
          goBackBtnClicked={this.props.goBackBtnClicked}
          initialNode={this.props.initialNode}
          saveRequested={this.props.saveRequested}
          reloadPermissions={this.props.reloadPermissions}
        />
      );
    }

    return (
      <div className="formContainer">
        <h2>Permission management</h2>
        {this.props.deleteRequested ? (
          <p>You have selected a permission to consult or edit.</p>
        ) : (
          <p>You have chosen to create a new permission. Enter the properties below and select a means of saving.</p>
        )}
        {innerform}
      </div>
    );
  }
}
export interface IEditPermissionFormProps {
  initialNode: Models.IPermissionViewModel;
  deleteRequested?: (node: Models.IPermissionViewModel) => void;
  saveRequested?: (node: Models.IPermissionViewModel) => void;
}
export interface IEditPermissionFormState {
  editingNode: Models.IPermissionViewModel;
}
export class EditPermissionForm extends React.Component<IEditPermissionFormProps, IEditPermissionFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: IEditPermissionFormProps) {
    super(props);
    this.state = { editingNode: this.props.initialNode };
  }
  manageSystemChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: e.target.checked,
          ManageProducts: false,
          ManagePublishers: false,
          ManageSubscription: false,
          ManageTitles: false,
          ManageAccounts: false,
          ManageContentReview: false,
          ManageBulletins: false,
          ManageFeedback: false,
          ManageOfflinePackages: false,
          ManageReporting: false,
          ManageTips: false,
          ManageAnnouncements: false,
          ManageStoreFront: false,
        },
      },
    }));
  };
  managePublisherChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: false,
          ManageProducts: false,
          ManagePublishers: e.target.checked,
          ManageSubscription: false,
          ManageTitles: false,
          ManageAccounts: false,
          ManageContentReview: false,
          ManageBulletins: false,
          ManageFeedback: false,
          ManageOfflinePackages: false,
          ManageReporting: false,
          ManageTips: false,
          ManageAnnouncements: false,
          ManageStoreFront: false,
        },
      },
    }));
  };
  manageProductsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageSystem: false, ManageProducts: e.target.checked, ManagePublishers: false },
      },
    }));
  };
  manageSubscriptionsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: false,
          ManagePublishers: false,
          ManageSubscription: e.target.checked,
          SubscriptionId: e.target.checked ? this.props.initialNode.Permission.SubscriptionId : null,
        },
      },
    }));
  };
  manageTitlesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageSystem: false, ManagePublishers: false, ManageTitles: e.target.checked },
      },
    }));
  };
  manageBulletinsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageSystem: false, ManagePublishers: false, ManageBulletins: e.target.checked },
      },
    }));
  };
  manageFeedbackChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageSystem: false, ManagePublishers: false, ManageFeedback: e.target.checked },
      },
    }));
  };
  manageTipsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageSystem: false, ManagePublishers: false, ManageTips: e.target.checked },
      },
    }));
  };
  manageOfflinePackagesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageOfflinePackages: e.target.checked },
      },
    }));
  };
  manageReportingChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageReporting: e.target.checked },
      },
    }));
  };
  manageContentReviewChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageSystem: false, ManagePublishers: false, ManageContentReview: e.target.checked },
      },
    }));
  };
  manageAccountsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageAccounts: e.target.checked,
          ManageSystem: false,
          ManageProducts: false,
          ManagePublishers: false,
          ManageSubscription: false,
          ManageTitles: false,
        },
      },
    }));
  };
  manageAnnouncementsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageAnnouncements: e.target.checked },
      },
    }));
  };
  manageStoreFrontChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageStoreFront: e.target.checked },
      },
    }));
  };
  permissionExtend = () => {
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ExpirationDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) },
      },
    }));
  };
  expiryChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    if (arg.target.valueAsDate === null) {
      return;
    }
    arg.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Permission: { ...prevState.editingNode.Permission, ExpirationDate: new Date(arg.target.valueAsDate!) } },
    }));
  };
  formValid = () => {
    if (
      !this.state.editingNode.Permission.ManageProducts &&
      !this.state.editingNode.Permission.ManagePublishers &&
      !this.state.editingNode.Permission.ManageSubscription &&
      !this.state.editingNode.Permission.ManageSystem &&
      !this.state.editingNode.Permission.ManageTitles &&
      !this.state.editingNode.Permission.ManageAccounts &&
      !this.state.editingNode.Permission.ManageBulletins &&
      !this.state.editingNode.Permission.ManageFeedback &&
      !this.state.editingNode.Permission.ManageTips &&
      !this.state.editingNode.Permission.ManageOfflinePackages &&
      !this.state.editingNode.Permission.ManageAnnouncements
    ) {
      return false;
    }
    if (this.state.editingNode.Permission.ExpirationDate !== null && new Date(this.state.editingNode.Permission.ExpirationDate) < new Date()) {
      return false;
    }
    return true;
  };

  render() {
    let subManage = "N/A";
    if (this.state.editingNode.Permission.SubscriptionId === null && this.state.editingNode.Permission.ManageSubscription) {
      subManage = "All";
    } else if (this.state.editingNode.Permission.SubscriptionId !== null) {
      subManage = this.state.editingNode.Permission.SubscriptionId.toString();
    }
    let isExpired = false;
    if (this.state.editingNode.Permission.ExpirationDate !== null && new Date(this.state.editingNode.Permission.ExpirationDate) < new Date()) {
      isExpired = true;
    }

    let expiryControl: JSX.Element;
    if (this.state.editingNode.Permission.ExpirationDate === null) {
      expiryControl = <Input disabled style={{ color: isExpired ? "red" : "" }} value={"Never"} type="text" name="expiration" id="expiration" />;
    } else {
      expiryControl = (
        <Input
          style={{ color: isExpired ? "red" : "" }}
          type="date"
          name="expiration"
          id="expiration"
          placeholder="Expiry Date (UTC)"
          value={Convert.formatDateForForm(new Date(this.state.editingNode.Permission.ExpirationDate))}
          onChange={this.expiryChanged}
        />
      );
    }

    let lockPubs = this.state.editingNode.Permission.ManageSystem;
    let lockSubs = lockPubs || this.state.editingNode.Permission.ManagePublishers;
    let locProds = lockSubs;
    let locTitles = lockSubs;

    let loginVal = this.state.editingNode.User!.LoginName;
    if (this.state.editingNode.AuthUser) {
      loginVal =
        this.state.editingNode.AuthUser.AuthUserName + " using " + Models.AuthProviderType[this.state.editingNode.AuthUser.AuthProvider] + " login provider";
    }
    return (
      <Form>
        <Col>
          <Row>
            <FormGroup style={{ flex: "1" }}>
              <Label for="username">Username</Label>
              <Input disabled value={loginVal} type="text" name="username" id="username" />
            </FormGroup>
            <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
              <Label for="publisher">Publisher</Label>
              <Input
                disabled
                value={this.state.editingNode.Permission.PublisherId === null ? "All" : this.state.editingNode.Publisher!.Name}
                type="text"
                name="publisher"
                id="publisher"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup style={{ flex: "1" }}>
              <Label for="created">Creation Date (UTC)</Label>
              <Input
                disabled
                value={Convert.dateToFormattedString(this.state.editingNode.Permission.CreatedDate, Languages.English)}
                type="text"
                name="created"
                id="created"
              />
            </FormGroup>
            <FormGroup style={{ marginLeft: "15px", marginRight: "15px", flex: "1" }}>
              <Label for="expiration">Expiry Date (UTC)</Label>
              {expiryControl}
            </FormGroup>
            <FormGroup style={{ marginRight: "15px", flex: "1" }}>
              <Label for="grantedBy">Granted by User ID</Label>
              <Input
                disabled
                value={
                  this.state.editingNode.Permission.GrantedByUserId +
                  (this.state.editingNode.Permission.GrantedByUserId === this.state.editingNode.Permission.UserId ? " (self assigned)" : "")
                }
                type="text"
                name="grantedBy"
                id="grantedBy"
              />
            </FormGroup>
            <FormGroup style={{ flex: "1" }}>
              <Label for="subId">Subscription ID</Label>
              <Input disabled value={subManage} type="text" name="subId" id="subId" />
            </FormGroup>
          </Row>
          <h3>Permissions</h3>
          <Row>
            {this.context.canManageSystem() && (
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="manageSystem">
                  <Input
                    onChange={this.manageSystemChanged}
                    checked={this.state.editingNode.Permission.ManageSystem}
                    type="checkbox"
                    name="manageSystem"
                    id="manageSystem"
                  />
                  Manage System
                </Label>
              </FormGroup>
            )}
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageAccounts">
                <Input
                  onChange={this.manageAccountsChanged}
                  checked={this.state.editingNode.Permission.ManageAccounts}
                  type="checkbox"
                  name="manageAccounts"
                  id="manageAccounts"
                />
                Manage Accounts
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="managePub">
                <Input
                  onChange={this.managePublisherChanged}
                  checked={this.state.editingNode.Permission.ManagePublishers}
                  disabled={lockPubs}
                  type="checkbox"
                  name="managePub"
                  id="managePub"
                />
                Manage Publisher
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageSubs">
                <Input
                  onChange={this.manageSubscriptionsChanged}
                  checked={this.state.editingNode.Permission.ManageSubscription}
                  disabled={lockSubs}
                  type="checkbox"
                  name="manageSubs"
                  id="manageSubs"
                />
                Manage Subscriptions
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageProds">
                <Input
                  onChange={this.manageProductsChanged}
                  checked={this.state.editingNode.Permission.ManageProducts}
                  disabled={locProds}
                  type="checkbox"
                  name="manageProds"
                  id="manageProds"
                />
                Manage Products
              </Label>
            </FormGroup>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageTitles">
                <Input
                  onChange={this.manageTitlesChanged}
                  checked={this.state.editingNode.Permission.ManageTitles}
                  disabled={locTitles}
                  type="checkbox"
                  name="manageTitles"
                  id="manageTitles"
                />
                Manage Titles
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageBulletins">
                <Input
                  onChange={this.manageBulletinsChanged}
                  checked={this.state.editingNode.Permission.ManageBulletins}
                  type="checkbox"
                  name="manageBulletins"
                  id="manageBulletins"
                />
                Manage Bulletins
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageFeedback">
                <Input
                  onChange={this.manageFeedbackChanged}
                  checked={this.state.editingNode.Permission.ManageFeedback}
                  type="checkbox"
                  name="manageFeedback"
                  id="manageFeedback"
                />
                Manage Feedback
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageTips">
                <Input
                  onChange={this.manageTipsChanged}
                  checked={this.state.editingNode.Permission.ManageTips}
                  type="checkbox"
                  name="manageTips"
                  id="manageTips"
                />
                Manage Tips
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageOfflinePackages">
                <Input
                  onChange={this.manageOfflinePackagesChanged}
                  checked={this.state.editingNode.Permission.ManageOfflinePackages}
                  type="checkbox"
                  name="manageOfflinePackages"
                  id="manageOfflinePackages"
                />
                Manage Offline Packages
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageporReting">
                <Input
                  onChange={this.manageReportingChanged}
                  checked={this.state.editingNode.Permission.ManageReporting}
                  type="checkbox"
                  name="manageporReting"
                  id="manageporReting"
                />
                Manage Reporting
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageContentReview">
                <Input
                  onChange={this.manageContentReviewChanged}
                  checked={this.state.editingNode.Permission.ManageContentReview}
                  type="checkbox"
                  name="manageContentReview"
                  id="manageContentReview"
                />
                Manage Content Review
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageAnnouncements">
                <Input
                  onChange={this.manageAnnouncementsChanged}
                  checked={this.state.editingNode.Permission.ManageAnnouncements}
                  type="checkbox"
                  name="manageAnnouncements"
                  id="manageAnnouncements"
                />
                Manage Announcements
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageStoreFront">
                <Input
                  onChange={this.manageStoreFrontChanged}
                  checked={this.state.editingNode.Permission.ManageStoreFront}
                  type="checkbox"
                  name="manageStoreFront"
                  id="manageStoreFront"
                />
                Manage Store Listings
              </Label>
            </FormGroup>
          </Row>
        </Col>
        <Col>
          <Row className="formButtons">
            {this.props.deleteRequested && (
              <Button onClick={() => this.props.deleteRequested!(this.state.editingNode)} color="danger" outline>
                Delete
              </Button>
            )}
            {this.props.saveRequested && (
              <Button disabled={!this.formValid()} onClick={() => this.props.saveRequested!(this.state.editingNode)} color="info" outline>
                Save
              </Button>
            )}
            {isExpired && (
              <Button onClick={this.permissionExtend} color="info" outline>
                Extend Permission 1 year
              </Button>
            )}
          </Row>
        </Col>
      </Form>
    );
  }
}

export interface ICreatePermissionFormProps {
  initialNode: Models.IPermissionViewModel;
  saveRequested?: (node: Models.IPermissionViewModel) => void;
  reloadPermissions: () => void;
}
export interface ICreatePermissionFormState {
  editingNode: Models.IPermissionViewModel;
  currentStep: number;
  expiresNever: boolean;
}
export class CreatePermissionForm extends React.Component<ICreatePermissionFormProps, ICreatePermissionFormState> {
  context: AppSession;
  static contextType = AppContext;
  constructor(props: ICreatePermissionFormProps) {
    super(props);
    this.state = { editingNode: this.props.initialNode, currentStep: 1, expiresNever: false };
  }
  manageSystemChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: e.target.checked,
          ManageProducts: false,
          ManagePublishers: false,
          ManageSubscription: false,
          ManageTitles: false,
          ManageAccounts: false,
          ManageBulletins: false,
          ManageFeedback: false,
          ManageTips: false,
          ManageAnnouncements: false,
          manageStoreFront: false,
        },
      },
    }));
  };
  manageAccountsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageAccounts: e.target.checked,
          ManageSystem: false,
          ManageProducts: false,
          ManagePublishers: false,
          ManageSubscription: false,
          ManageTitles: false,
        },
      },
    }));
  };
  managePublisherChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: false,
          ManageProducts: false,
          ManagePublishers: e.target.checked,
          ManageSubscription: false,
          ManageAccounts: false,
          ManageTitles: false,
          ManageBulletins: false,
          ManageFeedback: false,
          ManageTips: false,
          ManageAnnouncements: false,
          manageStoreFront: false,
        },
      },
    }));
  };
  manageProductsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageSystem: false, ManageProducts: e.target.checked, ManagePublishers: false },
      },
    }));
  };
  manageSubscriptionsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: false,
          ManagePublishers: false,
          ManageAccounts: false,
          ManageSubscription: e.target.checked,
          SubscriptionId: e.target.checked ? this.props.initialNode.Permission.SubscriptionId : null,
        },
      },
    }));
  };
  manageTitlesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: false,
          ManagePublishers: false,
          ManageAccounts: false,
          ManageTitles: e.target.checked,
        },
      },
    }));
  };
  manageBulletinsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: false,
          ManagePublishers: false,
          ManageBulletins: e.target.checked,
          PublisherId:
            this.state.editingNode.Permission.PublisherId === null
              ? this.context.loginLibraries.rows().sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))[0].PublisherId
              : this.state.editingNode.Permission.PublisherId,
        },
      },
    }));
  };
  manageFeedbackChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: false,
          ManagePublishers: false,
          ManageFeedback: e.target.checked,
          PublisherId:
            this.state.editingNode.Permission.PublisherId === null
              ? this.context.loginLibraries.rows().sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))[0].PublisherId
              : this.state.editingNode.Permission.PublisherId,
        },
      },
    }));
  };
  manageTipsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ManageSystem: false,
          ManagePublishers: false,
          ManageTips: e.target.checked,
          PublisherId:
            this.state.editingNode.Permission.PublisherId === null
              ? this.context.loginLibraries.rows().sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))[0].PublisherId
              : this.state.editingNode.Permission.PublisherId,
        },
      },
    }));
  };
  manageOfflinePackagesChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageOfflinePackages: e.target.checked },
      },
    }));
  };
  manageContentReviewChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageSystem: false, ManagePublishers: false, ManageContentReview: e.target.checked },
      },
    }));
  };
  manageReportingChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageReporting: e.target.checked },
      },
    }));
  };
  manageAnnouncementsChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageAnnouncements: e.target.checked },
      },
    }));
  };
  manageStoreFrontChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ManageStoreFront: e.target.checked },
      },
    }));
  };
  permissionExtend = () => {
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, ExpirationDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) },
      },
    }));
  };
  expiryChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    if (arg.target.valueAsDate === null) {
      return;
    }
    arg.persist();
    this.setState((prevState) => ({
      editingNode: { ...prevState.editingNode, Permission: { ...prevState.editingNode.Permission, ExpirationDate: new Date(arg.target.valueAsDate!) } },
    }));
  };
  publisherChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    if (+arg.target.value === -1) {
      this.setState((prevState) => ({ editingNode: { ...prevState.editingNode, Permission: { ...prevState.editingNode.Permission, SubscriptionId: null } } }));
    }
    console.log("New value is " + arg.target.value);
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: { ...prevState.editingNode.Permission, PublisherId: +arg.target.value === -1 ? null : +arg.target.value },
      },
    }));
  };
  subIdChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          SubscriptionId: arg.target.value === undefined || "" || +arg.target.value <= 0 ? null : +arg.target.value,
        },
      },
    }));
  };
  formValid = () => {
    if (this.state.currentStep === 1) {
      if (
        !this.state.editingNode.Permission.ManageProducts &&
        !this.state.editingNode.Permission.ManagePublishers &&
        !this.state.editingNode.Permission.ManageSubscription &&
        !this.state.editingNode.Permission.ManageSystem &&
        !this.state.editingNode.Permission.ManageTitles &&
        !this.state.editingNode.Permission.ManageAccounts &&
        !this.state.editingNode.Permission.ManageBulletins &&
        !this.state.editingNode.Permission.ManageFeedback &&
        !this.state.editingNode.Permission.ManageOfflinePackages &&
        !this.state.editingNode.Permission.ManageTips &&
        !this.state.editingNode.Permission.ManageSystem &&
        !this.state.editingNode.Permission.ManageReporting &&
        !this.state.editingNode.Permission.ManageContentReview
      ) {
        return false;
      }
      if (this.state.editingNode.Permission.ExpirationDate !== null && new Date(this.state.editingNode.Permission.ExpirationDate) < new Date()) {
        return false;
      }
    }
    return true;
  };

  moveStepForward = () => {
    if (this.state.editingNode.Permission.ManageSystem || this.state.editingNode.Permission.ManageAccounts) {
      this.setState({ currentStep: this.state.currentStep + 2 });
    } else {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };
  moveStepBackwards = () => {
    if (this.state.editingNode.Permission.ManageSystem || this.state.editingNode.Permission.ManageAccounts) {
      this.setState({ currentStep: this.state.currentStep - 2 });
    } else {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }
  };
  expiresNeverChanged = (arg: React.ChangeEvent<HTMLInputElement>) => {
    arg.persist();
    this.setState((prevState) => ({
      editingNode: {
        ...prevState.editingNode,
        Permission: {
          ...prevState.editingNode.Permission,
          ExpirationDate: arg.target.checked === false ? new Date(new Date().setFullYear(new Date().getFullYear() + 1)) : null,
        },
      },
      expiresNever: arg.target.checked,
    }));
  };
  getStepControls = (): JSX.Element => {
    if (this.state.currentStep === 1) {
      let isExpired = false;
      if (this.state.editingNode.Permission.ExpirationDate !== null && new Date(this.state.editingNode.Permission.ExpirationDate) < new Date()) {
        isExpired = true;
      }

      let expiryControl: JSX.Element;
      if (this.state.editingNode.Permission.ExpirationDate === null) {
        expiryControl = <Input disabled style={{ color: isExpired ? "red" : "" }} value={"Never"} type="text" name="expiration" id="expiration" />;
      } else {
        let date = Convert.formatDateForForm(new Date(this.state.editingNode.Permission.ExpirationDate));
        expiryControl = (
          <Input
            disabled={this.state.expiresNever}
            style={{ color: isExpired ? "red" : "" }}
            type="date"
            name="expiration"
            id="expiration"
            value={date}
            onChange={this.expiryChanged}
          />
        );
      }

      let lockPubs = this.state.editingNode.Permission.ManageSystem || this.state.editingNode.Permission.ManageAccounts;
      let lockSubs = lockPubs || this.state.editingNode.Permission.ManagePublishers;
      let locProds = lockSubs;
      let locTitles = lockSubs;
      return (
        <Col>
          <Row>
            <FormGroup style={{ marginLeft: "15px", marginRight: "15px", flex: "1" }}>
              <Label for="expiration">Expiry Date (UTC)</Label>
              {expiryControl}
              <Label style={{ marginLeft: "25px", marginTop: "15px" }} for="neverExp">
                <Input name="neverExp" id="neverExp" type="checkbox" onChange={this.expiresNeverChanged} />
                Never Expires
              </Label>
            </FormGroup>
          </Row>
          <h3>Permissions</h3>
          <Row>
            {this.context.canManageSystem() && (
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="manageSystem">
                  <Input
                    onChange={this.manageSystemChanged}
                    checked={this.state.editingNode.Permission.ManageSystem}
                    type="checkbox"
                    name="manageSystem"
                    id="manageSystem"
                  />
                  Manage System
                </Label>
              </FormGroup>
            )}
            {(this.context.canManageSystem() || this.context.canManageAccounts()) && (
              <FormGroup check style={{ flex: "1" }}>
                <Label check for="manageAccounts">
                  <Input
                    onChange={this.manageAccountsChanged}
                    checked={this.state.editingNode.Permission.ManageAccounts}
                    type="checkbox"
                    name="manageAccounts"
                    id="manageAccounts"
                  />
                  Manage Accounts
                </Label>
              </FormGroup>
            )}
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="managePub">
                <Input
                  onChange={this.managePublisherChanged}
                  checked={this.state.editingNode.Permission.ManagePublishers}
                  disabled={lockPubs}
                  type="checkbox"
                  name="managePub"
                  id="managePub"
                />
                Manage Publisher
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageSubs">
                <Input
                  onChange={this.manageSubscriptionsChanged}
                  checked={this.state.editingNode.Permission.ManageSubscription}
                  disabled={lockSubs}
                  type="checkbox"
                  name="manageSubs"
                  id="manageSubs"
                />
                Manage Subscriptions
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageProds">
                <Input
                  onChange={this.manageProductsChanged}
                  checked={this.state.editingNode.Permission.ManageProducts}
                  disabled={locProds}
                  type="checkbox"
                  name="manageProds"
                  id="manageProds"
                />
                Manage Products
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageTitles">
                <Input
                  onChange={this.manageTitlesChanged}
                  checked={this.state.editingNode.Permission.ManageTitles}
                  disabled={locTitles}
                  type="checkbox"
                  name="manageTitles"
                  id="manageTitles"
                />
                Manage Titles
              </Label>
            </FormGroup>
          </Row>
          <Row style={{ marginTop: "15px" }}>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageBulletins">
                <Input
                  onChange={this.manageBulletinsChanged}
                  checked={this.state.editingNode.Permission.ManageBulletins}
                  type="checkbox"
                  name="manageBulletins"
                  id="manageBulletins"
                />
                Manage Bulletins
              </Label>
            </FormGroup>

            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageFeedback">
                <Input
                  onChange={this.manageFeedbackChanged}
                  checked={this.state.editingNode.Permission.ManageFeedback}
                  type="checkbox"
                  name="manageFeedback"
                  id="manageFeedback"
                />
                Manage Feedback
              </Label>
            </FormGroup>

            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageTips">
                <Input
                  onChange={this.manageTipsChanged}
                  checked={this.state.editingNode.Permission.ManageTips}
                  type="checkbox"
                  name="manageTips"
                  id="manageTips"
                />
                Manage Tips
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageOfflinePackages">
                <Input
                  onChange={this.manageOfflinePackagesChanged}
                  checked={this.state.editingNode.Permission.ManageOfflinePackages}
                  type="checkbox"
                  name="manageOfflinePackages"
                  id="manageOfflinePackages"
                />
                Manage Offline Packages
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageReporting">
                <Input
                  onChange={this.manageReportingChanged}
                  checked={this.state.editingNode.Permission.ManageReporting}
                  type="checkbox"
                  name="manageReporting"
                  id="manageReporting"
                />
                Manage Reporting
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageContentReview">
                <Input
                  onChange={this.manageContentReviewChanged}
                  checked={this.state.editingNode.Permission.ManageContentReview}
                  type="checkbox"
                  name="manageContentReview"
                  id="manageContentReview"
                />
                Manage Content Review
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageAnnouncements">
                <Input
                  onChange={this.manageAnnouncementsChanged}
                  checked={this.state.editingNode.Permission.ManageAnnouncements}
                  type="checkbox"
                  name="manageAnnouncements"
                  id="manageAnnouncements"
                />
                Manage Announcements
              </Label>
            </FormGroup>
            <FormGroup check style={{ flex: "1" }}>
              <Label check for="manageStoreFront">
                <Input
                  onChange={this.manageStoreFrontChanged}
                  checked={this.state.editingNode.Permission.ManageStoreFront}
                  type="checkbox"
                  name="manageStoreFront"
                  id="manageStoreFront"
                />
                Manage Store Listings
              </Label>
            </FormGroup>
          </Row>
        </Col>
      );
    } else if (this.state.currentStep === 2) {
      return (
        <Col>
          <h3>Additional Details</h3>
          <Row>
            <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
              <Label for="publisher">Publisher</Label>
              <Input
                type="select"
                name="brandKey"
                value={
                  this.state.editingNode.Permission.PublisherId === null
                    ? this.context.loginLibraries.rows().sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))[0].PublisherId
                    : this.state.editingNode.Permission.PublisherId
                }
                onChange={this.publisherChanged}
              >
                {!this.state.editingNode.Permission.ManageBulletins &&
                  !this.state.editingNode.Permission.ManageFeedback &&
                  !this.state.editingNode.Permission.ManageTips && <option value={-1}>All</option>}
                {this.context.loginLibraries
                  .rows()
                  .sort((a, b) => (a.DisplayName > b.DisplayName ? 1 : -1))
                  .map((lib) => (
                    <option value={lib.PublisherId} key={lib.PublisherId}>
                      {lib.DisplayName}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Row>
          {this.state.editingNode.Permission.ManageSubscription && (
            <Row>
              <FormGroup style={{ marginLeft: "15px", flex: "1" }}>
                <h3>Subscription ID</h3>
                <span>
                  If set, user will be able to extend Licences to this subscription, but not manipulate the subscription itself. If left empty, the user can
                  create, modify, view all subscriptions and customers for the publisher and extend Licences.
                </span>
                <Input
                  type="number"
                  name="subId"
                  value={this.state.editingNode.Permission.SubscriptionId === null ? "" : this.state.editingNode.Permission.SubscriptionId}
                  disabled={this.state.editingNode.Permission.PublisherId === null}
                  onChange={this.subIdChanged}
                />
              </FormGroup>
            </Row>
          )}
        </Col>
      );
    } else if (this.state.currentStep === 3) {
      return <PermissionInviteForm permission={this.state.editingNode.Permission} wrapInForm={false} reloadPermissions={this.props.reloadPermissions} />;
    }
    return <span>Invalid step</span>;
  };
  render() {
    let controls = this.getStepControls();

    return (
      <Form>
        <Col>
          {controls}
          <Row className="formButtons">
            {this.state.currentStep > 1 && (
              <Button disabled={!this.formValid()} onClick={this.moveStepBackwards} color="info" outline>
                Previous
              </Button>
            )}
            {this.state.currentStep < 3 && (
              <Button disabled={!this.formValid()} onClick={this.moveStepForward} color="info" outline>
                Next
              </Button>
            )}
          </Row>
        </Col>
      </Form>
    );
  }
}
