import React from "react";
import { Button } from "reactstrap";
import { AppSession } from "src/models/AppSession";
import { AppContext } from "src/ui/state/Contextes";
import { Convert } from "src/utilities/Helpers";

import * as Models from "../../../models/dto/DashboardModels";
import { DataItem, DataRow, DataTable } from "../DataTable";
import { Action, IBatch, INode, IRequest, IResponse } from "../StandaloneCogniflow";

interface ITitleSelectorProps {
  publisherId: number;
  associatedTitles: Models.ITitle[];
  onChange: (newTitles: Models.ITitle[]) => void;
}

export class TitleSelector extends React.Component<ITitleSelectorProps> {
  context: AppSession;
  static contextType = AppContext;
  availableTitles: Models.ITitle[];
  associatedTitlesTable = React.createRef<DataTable>();
  availableTitlesTable = React.createRef<DataTable>();
  constructor(props: ITitleSelectorProps | Readonly<ITitleSelectorProps>) {
    super(props);
    this.state = {
      publisherId: props.publisherId,
    };
    this.availableTitles = [];
    this.addAllAvailableTitles = this.addAllAvailableTitles.bind(this);
    this.deleteAllAssociatedTitles = this.deleteAllAssociatedTitles.bind(this);
  }
  // #region Titles
  private generateTitle = (n: INode) => {
    let node = n as Models.ITitle;
    let dataItems = [];
    let attrs: any = {};
    attrs[Models.genericDataSettings.segmentDataDescriptor.secondaryIdDataAttribute] = node.TableId;
    attrs[Models.genericDataSettings.segmentDataDescriptor.mainIdDataAttribute] = node.Index;

    dataItems.push(<DataItem flexVal={2} className="" key={1} value={node.Name} />);
    dataItems.push(<DataItem flexVal={1} className="rightBorder leftBorder centerText" key={2} value={node.TableGuid} />);
    return <DataRow node={node} key={node.Index} attributes={attrs} dataItems={dataItems} rowEditRequested={this.changeTitleAttribution} />;
  };

  componentDidUpdate(prevProps: ITitleSelectorProps) {
    if (prevProps.associatedTitles.length !== this.props.associatedTitles.length) {
      this.associatedTitlesTable.current!.reload();
      this.availableTitlesTable.current!.reload();
    }
  }

  private async addAllAvailableTitles() {
    let holder: Models.ITitle[] = [];

    holder = JSON.parse(JSON.stringify(this.props.associatedTitles));
    let result = await this.context.getAllTitles({
      PublisherId: this.props.publisherId,
      ExcludeTitles: this.props.associatedTitles.map((x) => x.TableId),
      HideInactiveTitles: true!,
    });
    let allTitles = result.data.Titles;
    for (let i = 0; i < allTitles.length; i++) {
      let title = allTitles[i];
      holder.push(title);
    }
    this.availableTitles = [];
    this.props.onChange(holder);
  }

  private deleteAllAssociatedTitles() {
    let holder: Models.ITitle[] = [];
    holder = JSON.parse(JSON.stringify(this.props.associatedTitles));
    for (let i = 0; i < this.props.associatedTitles.length; i++) {
      let node = this.props.associatedTitles[i];
      this.availableTitles.push(node);
      holder.pop();
    }
    this.props.onChange(holder);
  }

  private changeTitleAttribution = (n: INode) => {
    let node = n as Models.ITitle;
    // Click is in the Associated Titles and should be removed.
    let holder: Models.ITitle[] = [];
    holder = JSON.parse(JSON.stringify(this.props.associatedTitles));
    if (this.props.associatedTitles.some((x) => x.TableId === node.TableId)) {
      const index = this.props.associatedTitles.findIndex((x) => x.TableId === node.TableId);
      if (index > -1) {
        holder.splice(index, 1);
      }
    }
    // Click is in the AvailableTitles and should be added
    else {
      holder.push(node);
    }
    this.props.onChange(holder);
  };

  private initializeLocalTitles = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>((resolve, reject) => {
      let result = this.props.associatedTitles;
      if (result === null) {
        reject();
        return;
      }
      let request: IBatch = {
        Action: Action.insert,
        AnchorMainId: 0,
        Nodes: [],
        BatchSize: Models.genericDataSettings.batchSize,
        TargetMainId: 0,
        Query: query,
      };
      request.Nodes = result;
      request.BatchSize = 10000;
      resolve({
        nodes: Convert.indexify(request).Nodes,
        targetSpine: 0,
      });
    });

  private availableTitleFlowProvider = (request: IRequest): Promise<IResponse> =>
    new Promise<IResponse>(async (resolve, reject) => {
      let result = await this.context.flowTitles({
        FlowRequest: request.Batches[0],
        PublisherId: this.props.publisherId,
        ExcludeTitles: this.props.associatedTitles.map((x) => x.TableId),
        HideInactiveTitles: true!,
      });
      if (result.valid()) {
        resolve({ Batches: [Convert.indexify(result.data.FlowResponse)] });
      } else {
        reject();
      }
    });

  private initializeAvailableTitles = (anchor?: number, query?: string): Promise<{ nodes: any[]; targetSpine: number }> =>
    new Promise<{ nodes: any[]; targetSpine: number }>(async (resolve, reject) => {
      if (this.props.associatedTitles === null) {
        reject();
        return;
      }
      this.availableTitles = [];
      let result = await this.context.flowTitles({
        FlowRequest: { Action: Action.insert, AnchorMainId: 0, Nodes: [], BatchSize: Models.genericDataSettings.batchSize, TargetMainId: 0, Query: query },
        PublisherId: this.props.publisherId,
        ExcludeTitles: this.props.associatedTitles.map((x) => x.TableId),
        HideInactiveTitles: true!,
      });
      let nodes = result.data.FlowResponse.Nodes;
      for (let node of nodes) {
        this.availableTitles.push(node as Models.ITitle);
      }
      if (result.valid()) {
        resolve({
          nodes: Convert.indexify(result.data.FlowResponse).Nodes,
          targetSpine: 0,
        });
      } else {
        reject();
      }
    });

  private localTitlesFlowProvider = (): Promise<IResponse> =>
    new Promise<IResponse>((resolve) => {
      resolve({ Batches: [] });
    });

  private productQueryExecute = (query: string) => {
    this.availableTitlesTable.current!.reload(query);
  };
  // #endregion

  render(): React.ReactNode {
    let setting = JSON.parse(JSON.stringify(Models.genericDataSettings));
    return (
      <div className="full-width full-height">
        <p>
          Select titles that should be associated with this user form. Click a title on the top to remove the association and click a title on the bottom to add
          the association.
        </p>
        <div>
          <span className="tableTitle">Associated titles</span>
          <span>
            <Button className="titleButton" onClick={this.deleteAllAssociatedTitles}>
              {" "}
              Remove all{" "}
            </Button>
          </span>
        </div>
        <div className="titleTables">
          <div className="associatedTitles">
            <DataTable
              tableClassName="full-height"
              headers={["Title", "Ref"]}
              headerFlexes={[2, 1]}
              flowProvider={this.localTitlesFlowProvider}
              initializeFlowProvider={this.initializeLocalTitles}
              objectBuilder={this.generateTitle}
              ref={this.associatedTitlesTable}
              settingsOverride={setting}
            />
          </div>
          <div>
            <span className="tableTitle">Available titles</span>
            <span>
              <Button className="titleButton" onClick={this.addAllAvailableTitles}>
                {" "}
                Add all{" "}
              </Button>
            </span>
          </div>
          <div className="availableTitles">
            <DataTable
              tableClassName="full-height"
              headers={["Title", "Ref"]}
              headerFlexes={[2, 1]}
              flowProvider={this.availableTitleFlowProvider}
              initializeFlowProvider={this.initializeAvailableTitles}
              objectBuilder={this.generateTitle}
              ref={this.availableTitlesTable}
              settingsOverride={setting}
              searchQueryComitted={this.productQueryExecute}
            />
          </div>
        </div>
      </div>
    );
  }
}
